import React, { useState, useEffect } from "react";
import { BiBitcoin } from "react-icons/bi";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
  Flex,
  Select,
  Button,
} from "@chakra-ui/react";
import { db, useAuth } from "../../firebase";
import { BudgetCard } from "./BudgetCard";
import axios from "axios";
import { EditGoalDrawer } from "../Modal/EditGoalDrawer";
import { marketData } from "../../Atoms";
import { useRecoilState } from "recoil";

export const BudgetAccordian = (props) => {
  const auth = useAuth();
  const [showGoalDrawer, setShowGoalDrawer] = useState(false);
  const [selected, setSelected] = useState(null);
  const [month, setMonth] = useState(new Date().getFullYear());
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [budgetGroups, setBudgetGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [filterType, setFilterType] = useState("default");
  const [marketState, setMarketState] = useRecoilState(marketData);

  const calculateSpent = async (
    groups,
    txns,
    m = new Date().getMonth(),
    y = new Date().getFullYear()
  ) => {
    let filteredTxns;
    let updatedGroups = [...groups];

    switch (filterType) {
      case "default":
        filteredTxns = filterByMonth(txns, m, y);
        break;

      default:
        break;
    }

    updatedGroups.map((group, index) => {
      group.categories.map((category, catIndex) => {
        let placeholderGoal = {
          amount: 150,
        };

        if (category.goal[0] === undefined) category.goal.push(placeholderGoal);

        category.goal.map((goal, gIndex) => {
          let total = 0;
          goal.fiatTotal = 0;

          goal.spent = [];
          filteredTxns.map((txn) => {
            let currType = goal.spent.findIndex(
              (i) => i.cryptoType === txn.cryptoType
            );

            if (txn.category === category.title) {
              // We need to flip the logic for "spend" type categories
              // Because we want to ensure "negative" values actually add to the goal
              // and "positive" values actually take away from the goal

              let fiatPrice =
                marketState.find((i) => i.ticker === txn.cryptoType).price || 0;

              if (category.type === "save") {
                total = total + parseFloat(txn.value);
                goal.fiatTotal = parseFloat(total) * parseFloat(fiatPrice);
                if (currType === -1) {
                  goal.spent.push({
                    cryptoType: txn.cryptoType,
                    total: total,
                  });
                } else {
                  goal.spent[gIndex].total = total;
                }
              } else if (category.type === "spend") {
                total = total + parseFloat(-txn.value);
                goal.fiatTotal = parseFloat(total) * parseFloat(fiatPrice);
                if (currType === -1) {
                  goal.spent.push({
                    cryptoType: txn.cryptoType,
                    total: total,
                  });
                } else {
                  goal.spent[gIndex].total = total;
                }
              }
            }

            return true;
          });
        });
      });
    });
    setBudgetGroups(updatedGroups);
  };

  /**
   * Use this to filter budgets by month and year
   * @param {String} txns → Pass transactions to filter through
   * @param {Number} month → Requires a number 0 - 11, this is received by doing `.getMonth()`
   * @param {Number} year → Requires a number, this is received by doing `.getFullYear()`
   */
  const filterByMonth = (
    txns,
    month = new Date().getMonth(),
    year = new Date().getFullYear()
  ) => {
    let newArr = [];
    txns.forEach((item) => {
      let txnMonth = new Date(item.date).getMonth();
      let txnYear = new Date(item.date).getFullYear();

      if (Number(month) === txnMonth && Number(year) === txnYear) {
        newArr.push(item);
      }
    });

    return newArr;
  };

  const toggleDrawer = (data) => {
    setShowGoalDrawer(data);
    if (data === false) {
      setSelected(null);
      setSelectedGroup(null);
    }
  };

  useEffect(() => {
    let mounted = true;
    db.collection("users")
      .doc(auth.user.uid)
      .collection("budgets")
      .doc("budget_1")
      .onSnapshot(async (doc) => {
        if (doc.exists) {
          if (mounted) {
            let allGroups = doc.data().groups;
            let allTxns = [];

            await doc.data().accounts.map((item) => {
              return (allTxns = [...allTxns, ...item.txns]);
            });

            setGroups(allGroups);
            setTransactions(allTxns);

            if (marketState.length > 0) {
              calculateSpent(allGroups, allTxns, new Date().getMonth());
            }
          }
        }
      });

    return function cleanup() {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (marketState.length > 0) {
      calculateSpent(groups, transactions, new Date().getMonth());
    }
  }, [marketState]);

  useEffect(() => {
    if (selected !== null) {
      setShowGoalDrawer(true);
    }
  }, [selected]);

  return (
    <>
      {showGoalDrawer && (
        <EditGoalDrawer
          toggleDrawer={toggleDrawer}
          selected={selected}
          group={selectedGroup}
          showGoalDrawer
        />
      )}
      <Flex>
        <Select
          w="150px"
          mr={3}
          borderRadius={"5px"}
          bg={"white"}
          border={"2px"}
          borderColor={"gray.100"}
          _hover={{ bg: "blue.100", borderColor: "blue.300" }}
          mb="5"
          size="lg"
          _focus={{ outline: "none" }}
          _active={{ outline: "none" }}
          onChange={(e) => {
            setMonth(Number(e.target.value));
            calculateSpent(
              budgetGroups,
              transactions,
              Number(e.target.value),
              year
            );
          }}
          placeholder={new Date().toLocaleString("default", { month: "long" })}
        >
          <option value={0}>January</option>
          <option value={1}>February</option>
          <option value={2}>March</option>
          <option value={3}>April</option>
          <option value={4}>May</option>
          <option value={5}>June</option>
          <option value={6}>July</option>
          <option value={7}>August</option>
          <option value={8}>September</option>
          <option value={9}>October</option>
          <option value={10}>November</option>
          <option value={11}>December</option>
        </Select>
        <Select
          w="100px"
          borderRadius={"5px"}
          bg={"white"}
          border={"2px"}
          borderColor={"gray.100"}
          _hover={{ bg: "blue.100", borderColor: "blue.300" }}
          mb="5"
          size="lg"
          _focus={{ outline: "none" }}
          _active={{ outline: "none" }}
          onChange={(e) => {
            setYear(Number(e.target.value));
            calculateSpent(
              budgetGroups,
              transactions,
              month,
              Number(e.target.value)
            );
          }}
        >
          <option value={2022}>2022</option>
          <option value={2021}>2021</option>
        </Select>
      </Flex>
      <Accordion
        variant="unstyled"
        allowToggle
        allowMultiple
        defaultIndex={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
      >
        {budgetGroups.map((item, mindex) => {
          return (
            <AccordionItem key={mindex} border={"none"}>
              <AccordionButton>
                <AccordionIcon mr="3" />
                <Box flex="1" textAlign="left">
                  <Heading fontWeight="bold" size="md">
                    {item.title}
                  </Heading>
                </Box>
              </AccordionButton>
              <AccordionPanel>
                <Flex
                  sx={{ gap: "25px" }}
                  flexWrap="wrap"
                  justifyContent="flex-start"
                  py="3"
                  px={3}
                >
                  {item.categories.map((bItem, bIndex) => {
                    return (
                      <BudgetCard
                        key={bIndex}
                        emoji={bItem.emoji}
                        type={bItem.type}
                        title={bItem.title}
                        goals={bItem.goal}
                        spent={bItem.fiatTotal}
                        //percent={((bItem.spent * 100) / bItem.goal).toFixed(2)}
                        onClick={() => {
                          setSelected(bItem);
                          setSelectedGroup(item.title);
                        }}
                      />
                    );
                  })}
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </>
  );
};
