import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Image,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React from "react";

export const Accounts = (props) => {
  return (
    <Box>
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Heading fontSize={"xl"}>Accounts</Heading>
        <Link to="/accounts">
          <Text
            fontWeight={700}
            color={"blue.400"}
            transition={"200ms"}
            _hover={{ textDecor: "underline", color: "blue.600" }}
          >
            View All →
          </Text>
        </Link>
      </Flex>
      <Box>
        {props.loading && (
          <Center h={"405px"}>
            <Spinner
              thickness="4px"
              speed="0.75s"
              emptyColor="gray.100"
              color="blue.300"
              size="xl"
            />
          </Center>
        )}
        {!props.loading && props.accounts.length === 0 && (
          <Stack>
            <Text>
              No accounts added, please connect a wallet or exchange to get
              started
            </Text>
            <Text fontStyle={"italic"}>
              If you've added an account - it may take a few minutes to fetch
              your accounts balances and transactions.
            </Text>
          </Stack>
        )}
        {!props.loading &&
          props.accounts.length !== 0 &&
          props.accounts.slice(0, 5).map((item, index) => {
            return (
              <Flex
                key={index}
                role="group"
                w={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
                borderRadius={"3px"}
                p={1}
                px={3}
                bg="white"
                border="2px"
                borderColor="gray.100"
                _hover={{
                  borderColor: "blue.300",
                }}
                transition={"0.2s"}
                mt={3}
              >
                <Flex alignItems={"center"}>
                  <Image
                    w={"36px"}
                    h={"36px"}
                    src={item.logo}
                    fallbackSrc={`https://avatars.dicebear.com/api/identicon/${item.uid}.svg`}
                  />
                  <Box p="3">
                    <Heading size="md" mb="1">
                      {item.accountTitle}
                    </Heading>
                    <Text fontSize="sm" fontStyle="italic">
                      {item.txns.length > 0
                        ? `${item.txns.length} Transactions`
                        : "No Transactions"}
                    </Text>
                  </Box>
                </Flex>
                <Box>
                  <Text fontSize="sm" fontWeight={"semibold"}>
                    ${Number(item.fiat_value).toLocaleString()}
                  </Text>
                </Box>
              </Flex>
            );
          })}
      </Box>
    </Box>
  );
};
