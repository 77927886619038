import {
  Center,
  Text,
  Flex,
  Image,
  Box,
  Heading,
  Spinner,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { db, useAuth } from "../../firebase";

export const Investments = () => {
  const [nfts, setNFTs] = useState([]);
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const auth = useAuth();

  const openSea = (addr) => {
    return axios.post(
      `https://us-central1-luumen-finance.cloudfunctions.net/api/luumen/opensea`,
      {
        address: addr,
      }
    );
  };

  useEffect(() => {
    setSpinnerLoader(true);
    db.collection("users")
      .doc(auth.user.uid)
      .collection("budgets")
      .doc("budget_1")
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          await doc.data().accounts.forEach(async (item) => {
            if (item.address.includes("0x")) {
              let arr = await openSea(item.address);
              setNFTs([...arr.data]);
              setSpinnerLoader(false);
            }
          });
          setSpinnerLoader(false);
        }
      });
  }, []);

  return (
    <Box h="90vh">
      <Heading>Your NFTs</Heading>
      {/* <Text fontWeight="semibold" mb="4">
        OpenSea is down for maintenance... We'll have NFTs shortly
      </Text> */}

      {spinnerLoader && (
        <Center h={"75vh"}>
          <Spinner
            thickness="4px"
            speed="0.75s"
            emptyColor="gray.100"
            color="blue.300"
            size="xl"
          />
        </Center>
      )}

      {!spinnerLoader && nfts.length === 0 && (
        <Center h={"75vh"} flexDir="column" opacity={0.2}>
          <Heading>No NFTs detected...</Heading>
          <Text mt={5} maxW={900} textAlign={"center"}>
            If you recently added an account, it may take a few minutes to fetch
            all of your onchain assets. If your account has been successfully
            synced, please make sure that your NFT's are on an ethereum address.
          </Text>
        </Center>
      )}

      <Flex
        sx={{ gap: "8px" }}
        justifyContent={"space-evenly"}
        flexWrap={"wrap"}
      >
        {nfts.length > 0 &&
          nfts?.map((item) => {
            return (
              <a
                key={item.id}
                href={item.permalink}
                target="_blank"
                rel="noreferrer"
              >
                <Box
                  w="100%"
                  maxW={"270px"}
                  transition={"0.2s"}
                  border={"2px"}
                  borderColor={"gray.100"}
                  _hover={{ borderColor: "blue.300" }}
                >
                  <Box w={"100%"} h={"250px"} overflow={"hidden"}>
                    <Image
                      height={"100%"}
                      objectFit={"cover"}
                      sx={{ transform: "scale(1.03)" }}
                      src={item.image_url}
                    />
                  </Box>
                  <Flex p={2} bg={"gray.50"}>
                    <Box>
                      <Text noOfLines={1}>
                        {item.name ? item.name : `#${item.token_id}`}
                      </Text>
                      <Text></Text>
                      <Text fontSize={"sm"}>
                        {new Date(
                          item.last_sale?.created_date
                        ).toLocaleDateString()}
                        {" • "}
                        {item.traits.length === 0
                          ? "No"
                          : item.traits.length}{" "}
                        Attributes
                      </Text>
                    </Box>
                  </Flex>
                </Box>
              </a>
            );
          })}
      </Flex>
    </Box>
  );
};
