import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Outlet, Navigate } from "react-router-dom";
import { Sidebar } from "../Sidebar/Sidebar";
import { useAuth } from "../../firebase";

export const Dashboard = () => {
  const auth = useAuth();

  // useEffect(() => {
  //   const q = query(collection(db, "users"));
  //   const unsub = onSnapshot(q, (querySnapshot) => {
  //     let tasksArray = [];
  //     querySnapshot.forEach((doc) => {
  //       tasksArray.push({ ...doc.data(), id: doc.id });
  //     });
  //     setTasks(tasksArray);
  //   });
  //   return () => unsub();
  // }, []);

  const renderDashboard = () => {
    return (
      <Sidebar>
        <Outlet />
      </Sidebar>
    );
  };

  return auth.user ? renderDashboard() : <Navigate to="/login" />;
};
