import { Box, Center, Spinner } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

export const Portfolio = (props) => {
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      width: 3,
    },
    colors: ["#4dc5ff"],
    title: {
      text: "Portfolio",
      align: "left",
    },
    xaxis: {
      categories: props.dates,
      labels: {
        show: true,
        hideOverlappingLabels: true,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          '<div class="arrow_box" style="padding:7px 14px;">' +
          "Amount: <br />" +
          "<span>" +
          series[seriesIndex][dataPointIndex].toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maxFractionDigits: 2,
          }) +
          "</span>" +
          "</div>"
        );
      },
    },
  });
  const [series, setSeries] = useState([
    {
      name: "Account Total",
      data: props.balances,
    },
  ]);

  useEffect(() => {
    setSeries([
      {
        name: "Account Total",
        data: props.balances,
      },
    ]);
  }, [props.balances]);

  useEffect(() => {
    setOptions({
      ...options,
      xaxis: {
        categories: props.dates,
      },
    });
  }, [props.dates]);

  return (
    <Box>
      {props.loading && (
        <Center h={"405px"}>
          <Spinner
            thickness="4px"
            speed="0.75s"
            emptyColor="gray.100"
            color="blue.300"
            size="xl"
          />
        </Center>
      )}
      {!props.loading && (
        <Chart height={"506px"} options={options} series={series} type="area" />
      )}
    </Box>
  );
};
