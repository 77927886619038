import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Box,
  Text,
  Button,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { BiChevronDown } from "react-icons/bi";
import { CgChevronLeft } from "react-icons/cg";
import { db, useAuth } from "../../firebase";

export const Dropdown = (props) => {
  const { isOpen } = useDisclosure();
  const auth = useAuth();
  const [selectedGroup, setSelectedGroup] = useState(null);

  const updateCategory = async (category) => {
    let allTxns = [];

    props.accounts.forEach((acc) => {
      acc.txns.forEach((item, index) => {
        if (item.txId === props.txn.txId) {
          item.category = category.title;
        }
      });
      allTxns.push(...acc.txns);
    });

    await db
      .collection("users")
      .doc(auth.user.uid)
      .collection("budgets")
      .doc("budget_1")
      .update({
        accounts: props.accounts,
      })
      .then(() => {
        props.updateData(allTxns);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const renderGroups = () => {
    return (
      <Box>
        {props.categories.map((item, index) => {
          return (
            <Button
              fontSize={"sm"}
              key={index}
              variant="unstyled"
              borderRadius="0"
              w="100%"
              textAlign="left"
              pl="3"
              fontWeight="regular"
              _hover={{ bg: "blue.100" }}
              onClick={() => {
                setSelectedGroup(item);
              }}
            >
              {item.title}
            </Button>
          );
        })}
      </Box>
    );
  };

  const renderCategories = () => {
    return (
      <Box>
        <Button
          fontSize={"sm"}
          variant="unstyled"
          borderRadius="0"
          w="100%"
          textAlign="left"
          pl="3"
          onClick={() => {
            setSelectedGroup(null);
          }}
        >
          <Flex alignItems="center" pr="2">
            <CgChevronLeft style={{ transform: "scale(1.2)" }} />
            <Text ml="1">{selectedGroup.title}</Text>
          </Flex>
        </Button>
        <MenuDivider m={0}></MenuDivider>

        {selectedGroup.categories.map((item, index) => {
          return (
            <MenuItem
              key={index}
              _hover={{ bg: "blue.100" }}
              onClick={() => {
                updateCategory(item);
              }}
            >
              {item.emoji} {item.title}
            </MenuItem>
          );
        })}
      </Box>
    );
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            variant={"unstyled"}
            rightIcon={<BiChevronDown />}
            pl="0"
            fontSize={"sm"}
            _focus={{ outline: "none" }}
          >
            {`${props.category}` || "Add a Category"}
          </MenuButton>
          <MenuList
            maxW="250px"
            bg="white"
            borderRadius={"5px"}
            border="2px"
            borderColor="gray.100"
            color="gray.700"
            py="0"
          >
            {selectedGroup === null ? renderGroups() : renderCategories()}
            <MenuDivider my="0" color={"gray.100"} />
            <Button
              fontSize={"sm"}
              variant="unstyled"
              borderRadius="0"
              w="100%"
              textAlign="left"
              _hover={{ bg: "blue.200" }}
              pl="3"
              onClick={() => {
                props.toggleDrawer(true, 1);
              }}
            >
              Create Group
            </Button>
            <MenuDivider my="0" color={"gray.100"} />
            <Button
              fontSize={"sm"}
              variant="unstyled"
              borderRadius="0"
              w="100%"
              textAlign="left"
              pl="3"
              _hover={{ bg: "blue.200" }}
              onClick={() => {
                props.toggleDrawer(true, 2);
              }}
            >
              Create Category
            </Button>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
