import React, { useState } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  useDisclosure,
  Button,
  Image,
  Avatar,
  Flex,
  Text,
  useToast,
  Box,
  Heading,
  Center,
} from "@chakra-ui/react";
import { useAuth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import firebase from "firebase";
import { loadStripe } from "@stripe/stripe-js";
import { useRecoilState } from "recoil";
import { userMetaData, tierData } from "../../Atoms";

export const Profile = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const auth = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const [userMetaState] = useRecoilState(userMetaData);
  const [tierState] = useRecoilState(tierData);
  const [loading, setLoading] = useState(false);

  const planType = () => {
    let plan;
    switch (userMetaState) {
      case userMetaState.subscription.tier === 0:
        plan = "Common";
        break;
      case userMetaState.subscription.tier === 1:
        plan = "Rare";
        break;
      case userMetaState.subscription.tier === 2:
        plan = "Epic";
        break;
      case userMetaState.subscription.tier === 3:
        plan = "Legendary";
        break;
      case userMetaState.subscription.tier === 9:
        plan = "Beta";
        break;

      default:
        break;
    }

    return plan;
  };

  return (
    <>
      <Flex
        borderTop={"1px"}
        borderBottom={"1px"}
        borderColor="gray.100"
        align="center"
        justifyContent={"center"}
        py={3}
        mb="15px"
        cursor="pointer"
        ref={btnRef}
        onClick={onOpen}
      >
        <Center p={1} bg="blue.500" borderRadius={"100px"}>
          <Avatar
            w={"35px"}
            h={"35px"}
            name="doodle_1926"
            bg={"blue.500"}
            src={`https://avatars.dicebear.com/api/bottts/${props.user.uid}.svg`}
          />
        </Center>
        <Text ml={"10px"}>{props.user?.email || "No user detected"}</Text>
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Profile</DrawerHeader>

          <DrawerBody>
            <Flex
              justifyContent={"space-between"}
              flexDir={"column"}
              h={"full"}
            >
              <Box>
                <Flex mb={6} alignItems={"center"}>
                  <Image
                    src={`https://avatars.dicebear.com/api/bottts/${props.user.uid}.svg`}
                    objectFit={"contain"}
                    bg={"blue.500"}
                    w={150}
                    h={150}
                    p={15}
                    mr={5}
                    borderRadius={250}
                  />
                  <Flex
                    bg={"white"}
                    borderRadius={3}
                    border={"2px"}
                    borderColor={"gray.100"}
                    flexDir={"column"}
                    justifyContent={"center"}
                    px={4}
                    py={2}
                    w={"full"}
                  >
                    <Text fontSize={"xs"} color={"gray.300"}>
                      EMAIL
                    </Text>
                    <Text>{props.user.email}</Text>
                    <Text fontSize={"xs"} color={"gray.300"}>
                      MEMBER SINCE
                    </Text>
                    <Text>
                      {new Date(
                        props.user.metadata.creationTime
                      ).toLocaleDateString()}
                    </Text>
                    <Text fontSize={"xs"} color={"gray.300"}>
                      PLAN
                    </Text>
                    <Text>
                      {tierState[userMetaState.subscription.tier].plan}
                    </Text>
                  </Flex>
                </Flex>
                <Box
                  bg={"white"}
                  borderRadius={3}
                  border={"2px"}
                  borderColor={"gray.100"}
                  p={4}
                  w={"full"}
                  mb={6}
                >
                  <Heading fontSize={"2xl"}>Membership</Heading>
                  <Text my={2} fontSize={"sm"}>
                    {userMetaState.subscription.active
                      ? `Thank you for supporting Luumen! Want to manage your membership? Cick the button below to open your portal.`
                      : `You're currently on the free tier, ${
                          tierState[userMetaState.subscription.tier].plan
                        }. To unlock all of
                    Luumen's abilities, subscribe to the Rare, Epic or Legendary
                    tier by clicking the button below.`}
                  </Text>
                  <Button
                    variant="luumen"
                    isLoading={loading}
                    w={"100%"}
                    onClick={async () => {
                      if (userMetaState.subscription.active) {
                        setLoading(true);
                        let portal = firebase
                          .functions()
                          .httpsCallable("stripePortal");

                        portal({
                          customerId: userMetaState.subscription.stripeId,
                        })
                          .then((result) => {
                            window.location.href = result.data.sessionUrl;
                          })
                          .catch((err) => {
                            setLoading(false);
                          });
                      } else {
                        navigate("/pricing");
                      }
                    }}
                  >
                    {userMetaState.subscription.active ? "Manage" : "Subscribe"}
                  </Button>
                </Box>
                <Box
                  bg={"white"}
                  borderRadius={3}
                  border={"2px"}
                  borderColor={"gray.100"}
                  p={4}
                  w={"full"}
                >
                  <Heading fontSize={"2xl"}>Your Invite Link</Heading>
                  <Text my={2} fontSize={"sm"}>
                    Share this link with your friends and family and when they
                    login and create an account, you receive a point!
                  </Text>
                  <Button
                    variant="luumen"
                    w={"100%"}
                    onClick={() => {
                      navigator.clipboard
                        .writeText(
                          `https://app.luumen.io/login?refer=${auth.user.uid}`
                        )
                        .then(() => {
                          toast({
                            title: "Copied!",
                            status: "info",
                            duration: 1000,
                            isClosable: true,
                            position: "bottom-right",
                          });
                        });
                    }}
                  >
                    {auth.user.uid}
                  </Button>
                </Box>
              </Box>

              <Button
                mt={6}
                variant="luumen"
                w="100%"
                onClick={() => {
                  auth.logout();
                }}
              >
                Logout
              </Button>
            </Flex>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
