import React, { useEffect } from "react";
import { BudgetAccordian } from "../Accordian/Accordian";
import { useNavigate } from "react-router-dom";
import { useAuth, db } from "../../firebase";

export const Budget = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    db.collection("users")
      .doc(auth.user.uid)
      .get()
      .then((doc) => {
        if (doc.data().betaAgreement === false) {
          navigate("/onboarding");
        }
      });
  }, []);
  return <BudgetAccordian />;
};
