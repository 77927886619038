import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Accounts } from "./components/Views/Accounts";
import { Dashboard } from "./components/Views/Dashboard";
import { Budget } from "./components/Views/Budget";
import { Login } from "./components/Views/Login";

import { Overview } from "./components/Views/Overview";
import { PrivateRoute } from "./components/Authentication/PrivateRoute";
import { Onboarding } from "./components/Views/Onboarding";
import "./globals.css";
import { Defi } from "./components/Views/Defi";
import { Transactions } from "./components/Views/Transactions";
import { Referrals } from "./components/Views/Referrals";
import { Investments } from "./components/Views/Investments";
import { useRecoilState } from "recoil";
import { marketData, userMetaData } from "./Atoms";
import { useAuth, db } from "./firebase";
import { Pricing } from "./components/Profile/Pricing";

// import { Authenticating } from "./components/Views/Authenticating";

function App() {
  let auth = useAuth();
  const [, setMarketState] = useRecoilState(marketData);
  const [userMetaDataState, setUserMetaData] = useRecoilState(userMetaData);

  useEffect(() => {
    db.collection("market")
      .doc("cmk")
      .get()
      .then((doc) => {
        setMarketState(doc.data().marketData);
      });
  }, []);

  useEffect(() => {
    if (auth.user) {
      db.collection("users")
        .doc(auth.user.uid)
        .get()
        .then((doc) => {
          setUserMetaData(doc.data());
        });
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route path="/" element={<Overview />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/budget" element={<Budget />} />
        <Route path="/investments" element={<Investments />} />
        <Route path="/defi" element={<Defi />} />
        <Route path="/referrals" element={<Referrals />} />
        <Route path="/pricing" element={<Pricing />} />
      </Route>

      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<Login />} />

      {/* <Route exact path="/authenticating" element={<Authenticating />} /> */}
      <Route
        exact
        path="/onboarding"
        element={
          <PrivateRoute>
            <Onboarding />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
