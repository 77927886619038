import React from "react";
import { Box, Text, Stack, Flex, chakra } from "@chakra-ui/react";

export const BudgetCard = (props) => {
  return (
    <Box
      overflow="hidden"
      minW="300px"
      w="23%"
      borderRadius={"3px"}
      bg={"white"}
      border={"2px"}
      borderColor={"gray.100"}
      _hover={{
        borderColor: "blue.300",
        cursor: "pointer",
      }}
      color="gray.700"
      transition="0.2s"
      {...props}
    >
      <Flex
        align="center"
        justify="space-between"
        px="3"
        py="4"
        borderBottomWidth="1px"
        bg="gray.50"
      >
        <Box>
          <Flex>
            <Text fontSize="xl" mr="1">
              {props.emoji}
            </Text>
            <Text fontSize="xl" fontWeight="bold">
              {props.title}
            </Text>
          </Flex>
        </Box>
      </Flex>
      <Stack spacing={0}>
        {props.goals.map((item, index) => {
          let percent = ((item.fiatTotal * 100) / item.amount).toFixed(2);

          return (
            <Box key={index}>
              <Flex
                className="progress-wrapper"
                align="center"
                justify="space-between"
                px="3"
                py="2"
                borderBottom={"2px"}
                borderColor={"gray.100"}
              >
                <Text fontWeight={"bold"}>
                  ${Number(item.amount).toLocaleString()}{" "}
                  <chakra.span fontSize={"xs"} fontWeight={"regular"}>
                    GOAL
                  </chakra.span>
                </Text>
                <Text fontWeight={"bold"}>
                  {parseFloat(percent).toLocaleString()}%
                </Text>
                <div
                  className={
                    "budget-progress " +
                    (props.percent >= 100 ? "budget-overspent" : "")
                  }
                ></div>
              </Flex>
            </Box>
          );
        })}
      </Stack>
      <Box px="3" py="2">
        <Text fontSize="xs" fontStyle="italic" color="gray.400">
          {props.goals[0].spent.length !== 0
            ? "Spent This Month"
            : "No transactions this month"}
        </Text>
        {props.goals.map((item, index) => {
          return item.spent.map((s, si) => {
            return (
              <Text key={si}>
                {s.total} {s.cryptoType}
              </Text>
            );
          });
        })}
      </Box>
    </Box>
  );
};
