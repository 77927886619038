import React from "react";
import {
  Image,
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
  chakra,
} from "@chakra-ui/react";
import {
  BiGridAlt,
  BiLayer,
  BiImages,
  BiUserPlus,
  BiBookBookmark,
  BiPulse,
  BiAbacus,
} from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.css";
import { useAuth } from "../../firebase";
import { Profile } from "../Profile/Profile";
import Logo from "../../assets/images/logo192.png";
// import { useRecoilState } from "recoil";
// import { filterState } from "../../Atoms";

export const Sidebar = (props) => {
  const sidebar = useDisclosure();
  const auth = useAuth();
  const location = useLocation();

  const NavGroup = (props) => {
    const { children, title, ...rest } = props;
    return (
      <Box px="5" pl="6" py="2" my="2px" {...rest}>
        <Text color="gray.200" textTransform={"uppercase"} fontSize={"xs"}>
          {title}
        </Text>
        {children}
      </Box>
    );
  };

  const NavItem = (props) => {
    const {
      icon,
      children,
      selected = false,
      disabled = false,
      ...rest
    } = props;
    return (
      <Flex
        align="center"
        py={"2"}
        cursor="pointer"
        color={selected ? "blue.300" : "gray.600"}
        _hover={{
          color: "blue.500",
        }}
        role="group"
        fontWeight="semibold"
        transition=".25s ease"
        className={disabled ? "disabled" : ""}
        _groupHover={{ color: "green" }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="2"
            boxSize="5"
            transition=".25s ease"
            color={selected ? "blue.300" : "gray.200"}
            _groupHover={{ color: "blue.500" }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    );
  };

  const SidebarContent = (props) => (
    <Flex
      justifyContent={"space-around"}
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="2"
      overflowX="hidden"
      bg={"white.600"}
      borderColor={useColorModeValue("inherit", "gray.700")}
      borderRightWidth="1px"
      w="300px"
      overflowY="hidden"
      {...props}
    >
      <Link to="/" style={{ color: "#383A48" }}>
        <Flex px="6" py="5" align="center">
          <Image w="25px" src={Logo} />
          <Text
            fontSize="2xl"
            ml="2"
            color={useColorModeValue("brand.500", "white")}
            fontWeight="semibold"
          >
            Luumen
          </Text>
        </Flex>
      </Link>

      <Flex
        direction="column"
        as="nav"
        color="gray.600"
        aria-label="Main Navigation"
      >
        <Box>
          <NavGroup title="Overview">
            <Link to="/">
              <NavItem
                selected={location.pathname === "/" ? true : false}
                icon={BiGridAlt}
                fontSize="md"
                fontWeight="regular"
              >
                <Text>
                  <strong>Snapshot</strong>
                </Text>
              </NavItem>
            </Link>
            <Link to="/investments">
              <NavItem
                selected={location.pathname === "/investments" ? true : false}
                icon={BiImages}
                fontSize="md"
                fontWeight="regular"
              >
                <Text>
                  <strong>Investments</strong>
                </Text>
              </NavItem>
            </Link>
          </NavGroup>

          <NavGroup title="Accounts">
            <Link to="/accounts">
              <NavItem
                selected={location.pathname === "/accounts" ? true : false}
                icon={BiLayer}
                fontSize="md"
                fontWeight="regular"
              >
                <Text>
                  <strong>Accounts</strong>
                </Text>
              </NavItem>
            </Link>

            <Link to="/transactions">
              <NavItem
                selected={location.pathname === "/transactions" ? true : false}
                icon={BiPulse}
                fontSize="md"
                fontWeight="regular"
              >
                <Text>
                  <strong>Transactions</strong>
                </Text>
              </NavItem>
            </Link>

            <Link to="/budget">
              <NavItem
                selected={location.pathname === "/budget" ? true : false}
                icon={BiAbacus}
                fontSize="md"
                fontWeight="regular"
                disabled={false}
              >
                <Text>
                  <strong>Budgets</strong>
                </Text>
              </NavItem>
            </Link>
          </NavGroup>

          <NavGroup title="Extras">
            <Link to="/referrals">
              <NavItem
                selected={location.pathname === "/referrals" ? true : false}
                icon={BiUserPlus}
                fontSize="md"
                fontWeight="regular"
              >
                <Text>
                  <strong>Referrals</strong>
                </Text>
              </NavItem>
            </Link>

            <a
              href="https://www.notion.so/luumen/Getting-Started-69d15928a1ee4f56b1b8bb8114e0ec0e"
              target="_blank"
              rel="noreferrer"
            >
              <NavItem
                selected={false}
                icon={BiBookBookmark}
                fontSize="md"
                fontWeight="regular"
              >
                <Text>
                  <strong>Guides</strong>
                </Text>
              </NavItem>
            </a>
          </NavGroup>
        </Box>
      </Flex>

      <Box pos="fixed" bottom="15px" w="300px">
        <Profile user={auth.user} />
        <Flex w={"100%"} justifyContent={"center"}>
          <chakra.a
            fontSize={"sm"}
            href="https://luumen.canny.io/report-bugs"
            rel="noreferrer"
            target="_blank"
            transition="0.2s"
          >
            Report a Bug
          </chakra.a>
          <Text mx={2}> | </Text>
          <chakra.a
            fontSize={"sm"}
            href="https://luumen.canny.io/feature-requests"
            rel="noreferrer"
            target="_blank"
            transition="0.2s"
          >
            Request a Feature
          </chakra.a>
        </Flex>
        <Box>
          <Text w={"100%"} textAlign={"center"} fontSize={"sm"}>
            Version - 0.0.18
          </Text>
        </Box>
      </Box>
    </Flex>
  );

  return (
    <Box bg="gray.25" as="section" minH="100vh">
      <SidebarContent
        bg={"white.500"}
        display={{ base: "none", md: "unset" }}
      />
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent bg={"white.500"} w="full" borderRight="none" />
        </DrawerContent>
      </Drawer>
      <Box ml={{ base: 0, md: "300px" }} transition=".3s ease">
        <Box as="main" p="4">
          {props.children}
        </Box>
      </Box>
    </Box>
  );
};
