import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Flex,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Spinner,
  Center,
  Heading,
} from "@chakra-ui/react";
import { BiChevronDown, BiDownload, BiExport, BiLockAlt } from "react-icons/bi";

import { TableHead } from "./TableHead";
import { TableRow } from "./TableRow";
import { useAuth, db } from "../../firebase";
import { EditTxn } from "../Modal/EditTxn";
import { marketData, userMetaData, tierData } from "../../Atoms";
import { useRecoilState } from "recoil";
import firebase from "firebase";
import { Parser } from "json2csv";
import { saveAs } from "file-saver";

export const Tables = (props) => {
  const auth = useAuth();
  const toast = useToast();

  const [marketState] = useRecoilState(marketData);
  const [userMetaState] = useRecoilState(userMetaData);
  const [tierState] = useRecoilState(tierData);

  const [categories, setCategories] = useState([]);
  const [groups, setGroups] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [viewAmount] = useState(25);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [accounts, SetAccounts] = useState([]);
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [spinnerLoader, setSpinnerLoader] = useState(false);

  const selectedTxn = (txn) => {
    setSelected(transactions[txn + offset]);
    toggleModal();
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const updateData = (txns) => {
    setTransactions(txns.sort((a, b) => (a.date > b.date ? -1 : 1)));
  };

  const exportTxns = () => {
    const json2csvParser = new Parser();
    let exports = JSON.parse(JSON.stringify(transactions));
    let exportedTxns = [];

    exports.forEach((e) => {
      let updatedTxn = {
        date: new Date(e.date).toLocaleString(),
        category: e.category,
        ticker: e.cryptoType,
        value: e.value,
        fee_amount: e.fee.amount,
        fee_ticker: e.fee.ticker,
        fiat_at_purchase_amount: e.fiat_at_purchase.amount,
        fiat_at_purchase_ticker: e.fiat_at_purchase.ticker,
        memo: e.memo,
        payee: e.payee,
        txId: e.txId,
      };

      exportedTxns.push(updatedTxn);
    });

    const csv = json2csvParser.parse(exportedTxns);

    var blob = new Blob([csv], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, `luumen-export-${new Date().toLocaleDateString()}.csv`);
  };

  const fetchData = () => {
    db.collection("users")
      .doc(auth.user.uid)
      .collection("budgets")
      .doc("budget_1")
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          let allCats = [];
          let allMain = [];
          let allTxns = [];

          await doc.data().groups.map((item) => {
            allCats = [...allCats, ...item.categories];
            return allMain.push(item.title);
          });

          await doc.data().accounts.map((item) => {
            return (allTxns = [...allTxns, ...item.txns]);
          });

          setPageCount(Math.ceil(allTxns.length / viewAmount));

          setTransactions(allTxns.sort((a, b) => (a.date > b.date ? -1 : 1)));

          SetAccounts(doc.data().accounts);
          setCategories(doc.data().groups);
          setGroups(allMain);
        }
      });
  };

  useEffect(() => {
    let mounted = true;
    setSpinnerLoader(true);

    db.collection("users")
      .doc(auth.user.uid)
      .collection("budgets")
      .doc("budget_1")
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          let allCats = [];
          let allMain = [];
          let allTxns = [];

          await doc.data().groups.map((item) => {
            allCats = [...allCats, ...item.categories];
            return allMain.push(item.title);
          });

          await doc.data().accounts.map((item) => {
            return (allTxns = [...allTxns, ...item.txns]);
          });

          setPageCount(Math.ceil(allTxns.length / viewAmount));

          if (mounted) {
            setTransactions(allTxns.sort((a, b) => (a.date > b.date ? -1 : 1)));

            SetAccounts(doc.data().accounts);
            setCategories(doc.data().groups);
            setGroups(allMain);
            setSpinnerLoader(false);
          }
        }
      });

    return function cleanup() {
      mounted = false;
    };
  }, []);

  return (
    <>
      {showModal && (
        <EditTxn
          isOpen={showModal}
          showModal={toggleModal}
          updateData={updateData}
          txn={selected}
          allTxns={transactions}
          categories={categories}
          accounts={accounts}
        />
      )}
      <Flex w="100%" mb="4" justifyContent="space-between">
        <Menu isLazy>
          {({ isOpen }) => (
            <>
              <MenuButton
                borderRadius={"5px"}
                bg={"white"}
                border={"2px"}
                borderColor={"gray.100"}
                _hover={{ bg: "blue.100", borderColor: "blue.300" }}
                isActive={isOpen}
                as={Button}
                rightIcon={<BiChevronDown />}
                variant="simple"
              >
                {filter === null ? "All Accounts" : filter.accountTitle}
              </MenuButton>
              <MenuList
                borderRadius={"5px"}
                bg={"white"}
                border={"2px"}
                borderColor={"gray.100"}
              >
                {filter !== null && (
                  <MenuItem
                    onClick={() => {
                      setFilter(null);
                      setCurrentPage(1);
                      setOffset(0);
                      setPageCount(Math.ceil(transactions.length / viewAmount));
                    }}
                  >
                    All Accounts
                  </MenuItem>
                )}
                {accounts.map((item, index) => {
                  return (
                    <MenuItem
                      _hover={{ bg: "blue.100" }}
                      onClick={() => {
                        setCurrentPage(1);
                        setOffset(0);
                        setFilter(item);
                        setPageCount(Math.ceil(item.txns.length / viewAmount));
                      }}
                      key={index}
                    >
                      {item.accountTitle}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </>
          )}
        </Menu>
        <Flex>
          <Button
            variant={"luumen"}
            px={8}
            _hover={{ bg: "blue.100", borderColor: "blue.300" }}
            loadingText="Syncing"
            isLoading={loading}
            onClick={() => {
              setLoading(true);
              const sync = firebase.functions().httpsCallable("syncAccount");

              sync({ uid: auth.user.uid })
                .then((res) => {
                  fetchData();
                  toast({
                    title: "Accounts have been synced",
                    status: "info",
                    duration: 3000,
                    isClosable: true,
                  });
                  setLoading(false);
                })
                .catch((err) => {
                  toast({
                    title: "We've encountered an error syncing your accounts",
                    description:
                      "Please try again, if this issue is persistent please reach out to support.",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                  });
                  setLoading(false);
                });
            }}
          >
            Sync Accounts
          </Button>
          <Button
            disabled={!userMetaState.subscription.active}
            variant={"luumen"}
            ml={3}
            onClick={exportTxns}
            leftIcon={
              !userMetaState.subscription.active ? (
                <BiLockAlt />
              ) : (
                <BiDownload />
              )
            }
          >
            Export Transactions
          </Button>
        </Flex>
      </Flex>
      <Table size="sm">
        <Thead>
          <TableHead />
        </Thead>
        <Tbody>
          {filter === null &&
            transactions
              .slice(offset, viewAmount * currentPage)
              .map((item, index) => {
                const fiat_at_purchase = {
                  amount: null,
                  ticker: null,
                };
                return (
                  <TableRow
                    updateData={updateData}
                    allTxns={transactions}
                    userId={auth.user.uid}
                    groups={groups}
                    userCategories={categories}
                    key={index}
                    txn={item}
                    accounts={accounts}
                    /* Callback from child */
                    selectTxn={selectedTxn}
                    /* DATA FROM TXNS */
                    txnIndex={index}
                    date={item.date}
                    category={item.category}
                    payee={item.payee}
                    memo={item.memo}
                    transaction_id={item.txId}
                    fee={item.fee}
                    fiat_at_purchase={
                      item.fiat_at_purchase
                        ? item.fiat_at_purchase
                        : fiat_at_purchase
                    }
                    account_address={item.account_address}
                    payee_address={item.payee_address}
                    value={item.value}
                    marketPrice={marketState.find(
                      (i) => i.ticker === item.cryptoType
                    )}
                  />
                );
              })}

          {filter &&
            filter.txns
              .sort((a, b) => (a.date > b.date ? -1 : 1))
              .slice(offset, viewAmount * currentPage)
              .map((item, index) => {
                const fiat_at_purchase = {
                  amount: null,
                  ticker: null,
                };
                return (
                  <TableRow
                    updateData={updateData}
                    allTxns={transactions}
                    userId={auth.user.uid}
                    groups={groups}
                    userCategories={categories}
                    key={index}
                    txn={item}
                    accounts={accounts}
                    /* Callback from child */
                    selectTxn={selectedTxn}
                    /* DATA FROM TXNS */
                    txnIndex={index}
                    date={item.date}
                    category={item.category}
                    payee={item.payee}
                    memo={item.memo}
                    transaction_id={item.txId}
                    fee={item.fee}
                    fiat_at_purchase={
                      item.fiat_at_purchase
                        ? item.fiat_at_purchase
                        : fiat_at_purchase
                    }
                    account_address={item.account_address}
                    payee_address={item.payee_address}
                    value={item.value}
                    marketPrice={marketState.find(
                      (i) => i.ticker === item.cryptoType
                    )}
                  />
                );
              })}
        </Tbody>
      </Table>

      {spinnerLoader && (
        <Center h={"75vh"}>
          <Spinner
            thickness="4px"
            speed="0.75s"
            emptyColor="gray.100"
            color="blue.300"
            size="xl"
          />
        </Center>
      )}

      {transactions.length === 0 && (
        <Center h={"75vh"} flexDir="column" opacity={0.2}>
          <Heading>No transactions detected...</Heading>
          <Text mt={5} maxW={750} textAlign={"center"}>
            If you recently added an account, it may take a few minutes to fetch
            all of your transactions. Once your account is successfully synced,
            your transactions will show up here.
          </Text>
        </Center>
      )}

      {transactions.length >= 25 && (
        <Flex py={2} justifyContent={"center"}>
          <Flex border="2px" borderColor={"gray.100"} borderRadius={5}>
            <Text
              py={2}
              px={3}
              _hover={{ background: "blue.100", cursor: "pointer" }}
              borderRight={"2px"}
              borderColor={"gray.100"}
              onClick={() => {
                if (currentPage > 1) {
                  setCurrentPage(currentPage - 1);
                  setOffset(offset - viewAmount);
                }
              }}
            >
              {"←"}
            </Text>
            <Text py={2} px={6} borderRight={"2px"} borderColor={"gray.100"}>
              {currentPage}
            </Text>
            <Text
              py={2}
              px={3}
              _hover={{ background: "blue.100", cursor: "pointer" }}
              onClick={() => {
                if (currentPage < pageCount) {
                  setCurrentPage(currentPage + 1);
                  setOffset(offset + viewAmount);
                }
              }}
            >
              {"→"}
            </Text>
          </Flex>
        </Flex>
      )}
    </>
  );
};
