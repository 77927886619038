import React, { useEffect, useState } from "react";
import { useAuth, db } from "../../firebase";
import {
  Box,
  Heading,
  Text,
  Flex,
  Stack,
  Image,
  Spacer,
} from "@chakra-ui/react";
import { AccountManagement } from "../Accounts/AccountManagement";
import { AddAccount } from "../Accounts/AddAccount";
import { useRecoilState } from "recoil";
import { marketData, userMetaData } from "../../Atoms";

export const Accounts = (props) => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(""); // Vezgo token
  const [vUser, setVUser] = useState();

  const [marketState] = useRecoilState(marketData);

  const auth = useAuth();
  const Vezgo = window.Vezgo;

  const initVezgo = async () => {
    const vezgo = Vezgo.init({
      clientId: "2eu68i9pg9qmt7o5j1ct4jder1",
      // authEndpoint: "http://localhost:5001/luumen-finance/us-central1/api/vezgo/auth",
      authEndpoint:
        "https://us-central1-luumen-finance.cloudfunctions.net/api/vezgo/auth",
      auth: {
        headers: { Authorization: `Bearer ${auth.user.uid}` },
      },
    });

    const user = vezgo.login(auth.user);
    setVUser(user);
    setToken(await user.getToken());
  };

  useEffect(() => {
    initVezgo();
  }, []);

  useEffect(() => {
    let mounted = true;

    db.collection("users")
      .doc(auth.user.uid)
      .collection("budgets")
      .doc("budget_1")
      .onSnapshot(async (doc) => {
        if (doc.exists) {
          let allCats = [];
          let allMain = [];
          await doc.data().groups.map((item) => {
            allCats = [...allCats, ...item.categories];
            allMain.push(item.title);
          });

          if (mounted) {
            setAccounts(doc.data().accounts);
            setSelectedAccount(doc.data().accounts[0]);
          }
        }
      });

    return function cleanup() {
      mounted = false;
    };
  }, []);

  return (
    <>
      <Box>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Heading mb="3">Accounts</Heading>
        </Flex>
        <Flex>
          <Stack
            w={"350px"}
            spacing={4}
            mr={"4"}
            overflowY="scroll"
            css={{
              "&::-webkit-scrollbar": {
                width: "0px",
              },
              "&::-webkit-scrollbar-track": {
                width: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "",
                borderRadius: "0px",
              },
            }}
          >
            <AddAccount vUser={vUser} accounts={accounts} />
            {accounts.length === 0 && (
              <Stack>
                <Text>
                  No accounts added, please connect a wallet or exchange to get
                  started
                </Text>
                <Text fontStyle={"italic"}>
                  If you've added an account - it may take a few minutes to
                  fetch your accounts balances and transactions.
                </Text>
              </Stack>
            )}
            {accounts.length !== 0 &&
              accounts.map((item, index) => {
                return (
                  <Box
                    cursor={"pointer"}
                    key={index}
                    role="group"
                    w={"100%"}
                    maxW="370px"
                    bg="white"
                    borderRadius={"3px"}
                    border="2px"
                    borderColor="gray.100"
                    _hover={{
                      borderColor: "blue.300",
                    }}
                    transition={"0.2s"}
                    onClick={() => {
                      setSelectedAccount(item);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <Flex
                      p="3"
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Flex>
                        <Image
                          mr={3}
                          w={"44px"}
                          h={"44px"}
                          src={item.logo}
                          fallbackSrc={`https://avatars.dicebear.com/api/identicon/${item.uid}.svg`}
                        />
                        <Box>
                          <Heading size="md">{item.accountTitle}</Heading>
                          <Text fontSize="sm" fontStyle="italic">
                            {item.txns.length > 0
                              ? `${item.txns.length} Transactions`
                              : "No Transactions"}
                          </Text>
                        </Box>
                      </Flex>
                      {item.synced && (
                        <Box>
                          <Text>
                            {new Date(item.synced).toLocaleDateString()}
                          </Text>

                          <Text>
                            {new Date(item.synced).toLocaleTimeString()}
                          </Text>
                        </Box>
                      )}
                    </Flex>
                    <Box
                      bg="gray.50"
                      transition={"0.2s"}
                      _groupHover={{ bg: "blue.200" }}
                      p="3"
                    >
                      <Text fontSize="sm" fontWeight={"semibold"}>
                        ${Number(item.fiat_value).toLocaleString()}
                      </Text>
                    </Box>
                  </Box>
                );
              })}
          </Stack>
          {selectedAccount != null && (
            <Stack
              minW={"410px"}
              spacing={0}
              height={"83vh"}
              overflowY={"scroll"}
              borderRadius={"3px"}
              css={{
                "&::-webkit-scrollbar": {
                  width: "0px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "0px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "",
                },
              }}
            >
              <Flex
                bg="gray.50"
                transition={"0.2s"}
                _groupHover={{ bg: "blue.100" }}
                py="4"
                pl="4"
                pr="1"
                border={"2px"}
                borderColor="gray.100"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Heading size="md" mr="2">
                  {selectedAccount.accountTitle}
                </Heading>

                <AccountManagement vUser={vUser} accounts={accounts} />
              </Flex>
              {selectedAccount.balances.map((item, index) => {
                let fiat = marketState.find(
                  (i) => i.ticker === item.cryptoType
                );

                if (fiat === undefined) {
                  fiat = { price: "Unable to find price" };
                }

                return (
                  <Flex
                    alignItems={"center"}
                    key={index}
                    bg="white"
                    borderRadius="0"
                    border="2px"
                    borderColor="gray.100"
                    borderTop="none"
                    color="gray.700"
                    p="3"
                    transition={"0.2s"}
                    borderBottomRadius={
                      index === selectedAccount.balances.length - 1
                        ? "3px"
                        : "0px"
                    }

                    // _hover={{ boxShadow: "4px 4px 0px #383A48" }}
                    // _focus={{ boxShadow: "4px 4px 0px #383A48 !important" }}
                  >
                    <Image
                      src={item.logo}
                      w={"28px"}
                      borderRadius={"22px"}
                      mr={"2"}
                      fallbackSrc={`https://avatars.dicebear.com/api/identicon/${item.cryptoType}.svg`}
                    ></Image>
                    <Heading size="sm" mr="2">
                      {item.cryptoType}
                    </Heading>
                    <Spacer />
                    <Stack spacing={"0"}>
                      <Heading size="sm" textAlign={"right"}>
                        {item.value} {item.cryptoType}
                      </Heading>
                      {typeof fiat.price === "string" ? (
                        <Text textAlign={"right"}>Unable to find price</Text>
                      ) : (
                        <Text textAlign={"right"}>{`${Math.abs(
                          fiat.price * item.value
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          maxFractionDigits: 2,
                        })}`}</Text>
                      )}
                    </Stack>
                  </Flex>
                );
              })}
            </Stack>
          )}
        </Flex>
      </Box>
    </>
  );
};
