import React, { useState, useEffect } from "react";

import {
  Button,
  Flex,
  Box,
  Center,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Input,
  Image,
  useToast,
  Divider,
} from "@chakra-ui/react";
import firebase from "firebase";
import { useNavigate } from "react-router";
import { db, useAuth } from "../../firebase";
import Logo from "../../assets/images/logo200.png";

export const Onboarding = () => {
  const [favoriteCrypto, setFavoriteCrypto] = useState("btc");
  const [accountTitle, setAccountTitle] = useState("");
  const [account, setAccount] = useState("");
  const [token, setToken] = useState(""); // Vezgo token
  const [vUser, setVUser] = useState();
  const [error, setError] = useState(
    "You need at least one transaction to connect your wallet"
  );
  const [referrer, setReferrer] = useState(null);
  const [firstAdded, setFirstAdded] = useState(false);
  const [step, setStep] = useState(0);

  const navigate = useNavigate();
  const auth = useAuth();
  const toast = useToast();
  const Vezgo = window.Vezgo;

  const initVezgo = async () => {
    const vezgo = Vezgo.init({
      clientId: "2eu68i9pg9qmt7o5j1ct4jder1",
      // authEndpoint: "http://localhost:5001/luumen-finance/us-central1/api/vezgo/auth",
      authEndpoint:
        "https://us-central1-luumen-finance.cloudfunctions.net/api/vezgo/auth",
      auth: {
        headers: { Authorization: `Bearer ${auth.user.uid}` },
      },
    });

    const user = vezgo.login(auth.user);
    setVUser(user);
    setToken(await user.getToken());
  };

  const stepOne = () => {
    return (
      <Box>
        <Image
          m="auto"
          w="64px"
          display={{ base: "block", md: "none" }}
          src={Logo}
        />
        <Text
          fontSize="4xl"
          w={[300, 400, 500]}
          textAlign="center"
          mx="auto"
          fontWeight="semibold"
          sx={{ opacity: "0.75" }}
          display={{ base: "block", md: "none" }}
        >
          Luumen
        </Text>
        <Text
          fontSize="3xl"
          w={[300, 400, 500]}
          textAlign="center"
          mx="auto"
          mb="35"
          fontWeight="semibold"
          sx={{ opacity: "0.59" }}
        >
          {firstAdded
            ? "Nice work! Let's add another one."
            : "Welcome! We’re here to help you budget your crypto assets!"}
        </Text>
        <Button
          onClick={() => {
            vUser.connect().onConnection(async (accountId) => {
              let acc = await vUser.accounts.getOne(accountId);
              setAccount(acc);
              setStep(1);
            });
          }}
          mt="5"
          size="lg"
          w="100%"
          variant={"luumen"}
          disabled={!vUser}
        >
          Add Account
        </Button>

        {firstAdded && (
          <>
            <Flex alignItems={"center"} my={4}>
              <Divider /> <Text mx="4">Or</Text> <Divider />
            </Flex>
            <FormControl mt="5">
              <Button
                onClick={() => {
                  navigate("/");
                }}
                size="lg"
                w="100%"
                variant={"luumen"}
                colorScheme="orange"
              >
                Continue to Overview
              </Button>
            </FormControl>
          </>
        )}

        {/* <Button
          onClick={async () => {
            let accounts = await vUser.accounts.getList();
            accounts.forEach(async (item, index) => {
              await vUser.accounts.remove(item.id);
            });

          }}
          disabled={!vUser}
        >
          List Accounts
        </Button> */}
      </Box>
    );
  };

  const stepTwo = () => {
    return (
      <Box>
        <Image
          m="auto"
          w="64px"
          display={{ base: "block", md: "none" }}
          src={Logo}
        />
        <Text
          fontSize="4xl"
          w={[300, 400, 500]}
          textAlign="center"
          mx="auto"
          fontWeight="semibold"
          sx={{ opacity: "0.75" }}
          display={{ base: "block", md: "none" }}
        >
          Luumen
        </Text>
        <Text
          fontSize="3xl"
          w={[300, 400, 500]}
          textAlign="center"
          mx="auto"
          mb="35"
          fontWeight="semibold"
          sx={{ opacity: "0.59" }}
        >
          Nice! Let’s name your new account and select you’re preferred Crypto
          Type
        </Text>
        <FormControl>
          <FormLabel>Account Name</FormLabel>
          <Input
            borderRadius={0}
            border={"2px"}
            borderColor={"gray.100"}
            _focus={{ outline: "blue.300" }}
            _hover={{ borderColor: "blue.300" }}
            _active={{ borderColor: "blue.300" }}
            size="lg"
            placeholder={account.provider?.name || "Coinbase Pro"}
            textTransform="capitalize"
            value={accountTitle}
            onChange={(e) => {
              setAccountTitle(e.target.value);
            }}
          />
        </FormControl>
        <FormControl mt="5">
          <FormLabel>Save your new account</FormLabel>
          <Button
            onClick={async () => {
              db.collection("queues")
                .doc(auth.user.uid)
                .set(
                  {
                    accounts: firebase.firestore.FieldValue.arrayUnion({
                      id: account.id,
                      title: accountTitle,
                    }),
                    email: auth.user.email,
                    count: 0,
                  },
                  { merge: true }
                )
                .then(() => {
                  if (referrer !== null) {
                    db.collection("users")
                      .doc(referrer)
                      .update({
                        invitees: firebase.firestore.FieldValue.arrayUnion(
                          auth.user.email
                        ),
                      });
                  }
                  setFirstAdded(true);
                  setAccountTitle("");
                  setStep(2);
                })
                .catch((err) => {
                  alert(
                    "Hey, we've encountered an error here. Please report this code to discord: 0XONB2"
                  );
                });
            }}
            size="lg"
            w="100%"
            variant={"luumen"}
            colorScheme="orange"
            disabled={accountTitle === "" ? true : false}
          >
            Save
          </Button>
        </FormControl>
      </Box>
    );
  };

  const stepThree = () => {
    return (
      <Box>
        <Image
          m="auto"
          w="64px"
          display={{ base: "block", md: "none" }}
          src={Logo}
        />
        <Text
          fontSize="4xl"
          w={[300, 400, 500]}
          textAlign="center"
          mx="auto"
          fontWeight="semibold"
          sx={{ opacity: "0.75" }}
          display={{ base: "block", md: "none" }}
        >
          Luumen
        </Text>
        <Text
          fontSize="3xl"
          w={[300, 400, 500]}
          textAlign="center"
          mx="auto"
          mb="35"
          fontWeight="semibold"
          sx={{ opacity: "0.59" }}
        >
          It’s been done! Would you like to add another account?
        </Text>

        <Button
          onClick={() => {
            setStep(0);
          }}
          size="lg"
          w="100%"
          variant={"luumen"}
          colorScheme="orange"
        >
          Add a New Account
        </Button>
        <Flex alignItems={"center"} my={4}>
          <Divider /> <Text mx="4">Or</Text> <Divider />
        </Flex>
        <FormControl mt="5">
          <Button
            onClick={() => {
              navigate("/");
            }}
            size="lg"
            w="100%"
            variant={"luumen"}
            colorScheme="orange"
          >
            Continue to Overview
          </Button>
        </FormControl>
      </Box>
    );
  };

  useEffect(() => {
    initVezgo();
    db.collection("users")
      .doc(auth.user.uid)
      .get()
      .then((doc) => {
        if (doc.data().referrer !== null) {
          setReferrer(doc.data().referrer);
        }
      });
  }, []);

  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
      <Center
        flex={1}
        bgGradient="linear(150.28deg, rgba(189, 147, 249, 0.52) 4.17%, rgba(147, 244, 171, 0.26) 100%)"
        display={{ base: "none", md: "flex" }}
      >
        <Box textAlign="center">
          <Image m="auto" w="104px" src={Logo} />
          <Text
            color="gray.700"
            fontSize="3xl"
            fontWeight="semibold"
            m="0"
            sx={{ transform: "translateY(15px)", opacity: "0.59" }}
          >
            Welcome to
          </Text>
          <Text color="gray.700" fontSize="5xl" fontWeight="semibold" m="0">
            Luumen
          </Text>
        </Box>
      </Center>
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        {step === 0 ? stepOne() : step === 1 ? stepTwo() : stepThree()}
      </Flex>
    </Stack>
  );
};
