import { Center, Button, Text } from "@chakra-ui/react";
import React from "react";

export const Defi = () => {
  return (
    <Center h="90vh" flexDir="column">
      <Text fontWeight="semibold" mb="4">
        Connect your wallet to begin growing your crypto assets{" "}
      </Text>
      <Button variant="with-shadow">Coming Soon</Button>
    </Center>
  );
};
