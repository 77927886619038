import React, { useState, useContext, createContext } from "react";
import axios from "axios";
import { useAuth, db } from "./firebase";
import { useNavigate } from "react-router";
import firebase from "firebase";

const APIContext = createContext();

export const useAPI = () => {
  return useContext(APIContext);
};

export const APIProvider = ({ children }) => {
  const navigate = useNavigate();
  const auth = useAuth();

  /**
   * Call getTransactions(network) when we want to fetch new transactions
   * @param {String} network
   */
  const getTransactions = async (network) => {
    console.log("Loading transactions");

    switch (network) {
      case "btc":
        blockchainAddress();
        break;

      default:
        break;
    }
  };

  /**
   * Call getTransactions(network) when we want to fetch new transactions
   * @param {String} network
   */
  const updateBalances = async (network, address) => {
    switch (network) {
      case "btc":
        blockchainBalance(address);
        break;

      case "eth":
        etherscanBalance(address);
        break;

      default:
        break;
    }
  };

  /**
   * Call createAccount(network) when we want to add an address
   * @param {String} network
   */
  const createAccount = async (network, address, txns) => {
    console.log("Loading transactions");

    switch (network) {
      case "btc":
        blockchainCreateAccount();
        break;

      case "eth":
        etherscanAddressLookUp(address, txns);
        break;

      default:
        break;
    }
  };

  /**
   * Allows us to create an account from a btc address via Blockchain.info
   * @param {String} address
   * @param {String} network
   * @param {String} accountTitle
   */
  const blockchainCreateAccount = async (address, network, accountTitle) => {
    await axios
      .get(`https://blockchain.info/rawaddr/${address}`)
      .then(async (res) => {
        let txns = [];
        let balance = res.data.final_balance / 100000000;

        // TODO: For Outgoing(Loss) Txns Loop through each txns `out` array - check `addr` for addresses that are not ours
        // TODO: For Incoming(Gain) Txns Loop through each txns `out` array - check `addr` for addresses that are not ours

        res.data.txs.map((item) => {
          let txn = {
            cryptoType: network,
            address: address,
            txId: item.hash,
            date: item.time,
            value: item.result / 100000000,
            memo: "",
            category: "Uncategorized",
            payee: "",
            approved: false,
          };
          return txns.push(txn);
        });

        console.log("Update users txns with these: ", txns);

        await db
          .collection("users")
          .doc(auth.user.uid)
          .collection("budgets")
          .doc("budget_1")
          .update({
            accounts: [
              {
                address: address,
                cryptoType: network,
                accountTitle: accountTitle,
                accountTotal: balance,
                accountType: "checking",
              },
            ],
            txns: txns,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Allows us to fetch new transactions from a btc address via Blockchain.info
   * @param {String} address
   * @param {String} network
   */
  const blockchainAddress = async (address, network) => {
    await axios
      .get(`https://blockchain.info/address/${address}?limit=20&format=json`)
      .then(async (res) => {
        let txns = [];

        res.data.txs.map((item) => {
          let txn = {
            cryptoType: network,
            address: address,
            txId: item.hash,
            date: item.time,
            value: item.result / 100000000,
            memo: "",
            category: "Uncategorized",
            payee: "",
            approved: false,
          };
          return txns.push(txn);
        });

        console.log("Update users txns with these: ", txns);

        await db
          .collection("users")
          .doc(auth.user.uid)
          .collection("budgets")
          .doc("budget_1")
          .update({
            txns: txns,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Allows us to fetch new balance from a btc address via Blockchain.info
   * @param {String} address
   */
  const blockchainBalance = async (address) => {
    await axios
      .get(`https://blockchain.info/rawaddr/${address}`)
      .then(async (res) => {
        console.log("Users current bal: ", res.data.final_balance);

        // await db
        //   .collection("users")
        //   .doc(auth.user.uid)
        //   .collection("budgets")
        //   .doc("budget_1")
        //   .update({
        //     txns: txns,
        //   });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const etherscanAddressLookUp = async (address, transactions) => {
    const getEthTxns = firebase.functions().httpsCallable("getEthTransactions");
    await getEthTxns({ address: address })
      .then(async (result) => {
        let txns = [];
        let oldTxns = transactions;

        console.log(result.data);

        result.data.res.result
          .reverse()
          .slice(0, 25) // limit the amount of txns to 25
          .map(async (item) => {
            let value;

            if (item.to === address.toLowerCase()) {
              value = item.value;
            } else {
              value = -item.value;
            }

            let txn = {
              cryptoType: "eth",
              address: address,
              txId: item.hash,
              date: item.timeStamp,
              value: Number(value) / 1000000000000000000,
              memo: "",
              category: "Uncategorized",
              payee: "",
              toAddress: item.to,
              fromAddress: item.from,
              approved: false,
            };

            txns.push(txn);
          });

        return txns;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Updates ethereum addresses via etherscan api
   * @param {String} address
   */
  const etherscanBalance = async (address) => {
    console.log("requesting a balance update for: ", address);

    // await axios
    //   .get(`https://blockchain.info/rawaddr/${address}`)
    //   .then(async (res) => {
    //     console.log("Users current bal: ", res.data.final_balance);

    //     // await db
    //     //   .collection("users")
    //     //   .doc(auth.user.uid)
    //     //   .collection("budgets")
    //     //   .doc("budget_1")
    //     //   .update({
    //     //     txns: txns,
    //     //   });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const values = {
    createAccount,
    updateBalances,
    getTransactions,
  };

  return <APIContext.Provider value={values}>{children}</APIContext.Provider>;
};
