import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  useColorModeValue as mode,
  useToast,
} from "@chakra-ui/react";
import * as React from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { useAuth } from "../../firebase";

export const PasswordField = React.forwardRef((props, ref) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef(null);
  const mergeRef = useMergeRefs(inputRef, ref);
  const auth = useAuth();
  const toast = useToast();

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;

    if (input) {
      input.focus({
        preventScroll: true,
      });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  return (
    <FormControl id="password" my="6">
      <Flex justify="space-between">
        <FormLabel>Password</FormLabel>
        {props.showForgot && (
          <Box
            as="a"
            color="blue.300"
            fontWeight="semibold"
            fontSize="sm"
            cursor="pointer"
            _hover={{ color: "blue.100" }}
            onClick={() => {
              if (props.email !== "") {
                auth
                  .sendPasswordResetEmail(props.email)
                  .then(() => {
                    toast({
                      title: "We've sent you a password reset link!",
                      variant: "subtle",
                      duration: 3000,
                    });
                  })
                  .catch((err) => {
                    toast({
                      title: err.message,
                      status: "error",
                      variant: "subtle",
                      duration: 3000,
                    });
                  });
              }
            }}
          >
            Forgot Password?
          </Box>
        )}
      </Flex>
      <InputGroup size="lg">
        <InputRightElement>
          <IconButton
            border="none"
            _hover={{ boxShadow: "none" }}
            bg="transparent !important"
            variant="ghost"
            aria-label={isOpen ? "Mask password" : "Reveal password"}
            icon={isOpen ? <HiEyeOff /> : <HiEye />}
            onClick={onClickReveal}
          />
        </InputRightElement>
        <Input
          borderRadius={0}
          border={"2px"}
          borderColor={"gray.100"}
          _focus={{ outline: "blue.300" }}
          _hover={{ borderColor: "blue.300" }}
          _active={{ borderColor: "blue.300" }}
          size="lg"
          ref={mergeRef}
          name="password"
          type={isOpen ? "text" : "password"}
          autoComplete="current-password"
          required
          {...props}
        />
      </InputGroup>
    </FormControl>
  );
});

PasswordField.displayName = "PasswordField";
