import {
  Stack,
  Button,
  Text,
  Box,
  Heading,
  HStack,
  useToast,
  Divider,
  Image,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { db, useAuth } from "../../firebase";
import Rewards from "../../assets/images/rewards.png";

export const Referrals = () => {
  const toast = useToast();
  const auth = useAuth();

  const [invitees, setInvitees] = useState([]);

  useEffect(() => {
    db.collection("users")
      .doc(auth.user.uid)
      .get()
      .then(async (doc) => {
        if (doc.data().invitees !== undefined) {
          setInvitees(doc.data().invitees);
        }
      });
  }, []);

  return (
    <Stack m={"-16px"} spacing={8}>
      <Box px={8} py={14} bg={"blue.100"}>
        <Heading>Invite your friends, earn rewards</Heading>
        <Text my={3} maxW={"650px"}>
          Each friend that you invite, adds a point to your Referral Level, evey
          10 levels you'll be able to redeem a <em>free</em> NFT. Can you
          collect them all?
        </Text>
      </Box>
      <HStack px={8} flexWrap={"wrap-reverse"} alignItems={"flex-end"}>
        <Box
          bg={"white"}
          border={"2px"}
          borderRadius={3}
          borderColor={"gray.100"}
          p={4}
          w={"60%"}
        >
          <Heading fontSize={"2xl"}>Rewards</Heading>
          <Text my={2} fontSize={"sm"}>
            Our rewards are coming soon! You'll gain a Referral Point for each
            person you refer. While our rewards are being worked on, you are
            able to start referring people using your code.
          </Text>
          <Image mx={"auto"} mt={8} src={Rewards} />
        </Box>
        <Box
          bg={"white"}
          borderRadius={3}
          border={"2px"}
          borderColor={"gray.100"}
          p={4}
          w={"25%"}
          minWidth={"375px"}
        >
          <Heading fontSize={"2xl"}>Your Invite Link</Heading>
          <Text my={2} fontSize={"sm"}>
            Share this link with your friends and family and when they login and
            create an account, you receive a point!
          </Text>
          <Button
            _hover={{ bg: "blue.100", borderColor: "blue.300" }}
            borderRadius={0}
            bg={"white"}
            border={"2px"}
            borderColor={"gray.100"}
            px={8}
            py={4}
            w={"100%"}
            onClick={() => {
              navigator.clipboard
                .writeText(`https://app.luumen.io/login?refer=${auth.user.uid}`)
                .then(() => {
                  toast({
                    title: "Copied!",
                    status: "info",
                    duration: 1000,
                    isClosable: true,
                    position: "bottom-right",
                  });
                });
            }}
          >
            {auth.user.uid}
          </Button>

          <Heading mt={3} fontSize={"xl"}>
            Referral Points: {invitees.length > 0 ? invitees.length : "0"}
          </Heading>

          {invitees.length > 0 && (
            <>
              <Divider my={2} mb={2} />
              {invitees.map((item, index) => {
                return <Text key={index}>{item}</Text>;
              })}
            </>
          )}
        </Box>
      </HStack>
    </Stack>
  );
};
