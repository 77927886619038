import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./firebase";
import { APIProvider } from "./API";
import "@fontsource/work-sans";
import { RecoilRoot } from "recoil";

const theme = extendTheme({
  fonts: {
    heading: "Work Sans",
    body: "Work Sans",
  },
  colors: {
    blue: {
      50: "#daf9ff",
      100: "#ade7ff",
      200: "#7dd5ff",
      300: "#4dc5ff",
      400: "#24b4fe",
      500: "#119be5",
      600: "#0088CA",
      700: "#005681",
      800: "#003450",
      900: "#001220",
    },
    purple: {
      50: "#e8eeff",
      100: "#c0ccf3",
      200: "#99aae6",
      300: "#7188da",
      400: "#4966cf",
      500: "#304db6",
      600: "#253c8e",
      700: "#192b66",
      800: "#0d1a40",
      900: "#01091b",
    },
    gray: {
      25: "#F8F9FB",
      50: "#f1f1fd",
      100: "#d4d8df",
      200: "#bcbec4",
      300: "#a3a4aa",
      400: "#888a90",
      500: "#6f7077",
      600: "#56575d",
      700: "#3e3e44",
      800: "#25252c",
      900: "#0b0b16",
    },
    white: {
      500: "#ffffff",
      600: "#fbf9ff",
    },
  },
  components: {
    Button: {
      baseStyle: {},
      variants: {
        luumen: {
          borderRadius: "5px",
          backgroundColor: "white",
          border: "2px",
          borderColor: "gray.100",
          _hover: { backgroundColor: "blue.100", borderColor: "blue.500" },
          _focus: { boxShadow: "none" },
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          color: "gray.700",
          borderRadius: "0px",
          backgroundColor: "white !important",
          border: "2px",
          borderColor: "gray.100",
          outline: "none",
          _hover: { backgroundColor: "blue.200", borderColor: "blue.500" },
          ":focus": {
            outline: "none !important",
          },
          _active: {
            outline: "none",
          },
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <RecoilRoot>
          <APIProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </APIProvider>
        </RecoilRoot>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
