import { useState, useEffect, useContext, createContext } from "react";
import firebase from "firebase";
import "firebase/auth";
import "firebase/analytics";
import { Magic } from "magic-sdk";

const magic = new Magic("pk_live_5579973030FF6992");

const firebaseConfig = {
  apiKey: "AIzaSyDA1dB0gaskxcpYQ8O6V737Af1ssKVBKiI",
  authDomain: "luumen-finance.firebaseapp.com",
  projectId: "luumen-finance",
  storageBucket: "luumen-finance.appspot.com",
  messagingSenderId: "833654870287",
  appId: "1:833654870287:web:a53868545d3206aedf2e7a",
  measurementId: "G-TNWTN36QS7",
};

// eslint-disable-next-line
const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const AuthContext = createContext();
// eslint-disable-next-line
const analytics = firebase.analytics();

export { db };

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const login = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        setUser(response.user);

        return response.user;
      });
  };

  const loginWithMagic = async (email, referral) => {
    const didToken = await magic.auth.loginWithMagicLink({ email });
    const auth = firebase.functions().httpsCallable("authMagic");
    let result = (await auth({ didToken })).data;

    await firebase
      .auth()
      .signInWithCustomToken(result.token)
      .then(async (response) => {
        setUser(response.user);

        if (result.newUser) {
          await db
            .collection("users")
            .doc(response.user.uid)
            .set({
              email: email,
              createdDate: firebase.firestore.FieldValue.serverTimestamp(),
              betaAgreement: true,
              referrer: referral,
              subscription: {
                active: false,
                tier: -1,
                stripeId: result.stripeId,
              },
            })
            .then(async () => {
              await db
                .collection("users")
                .doc(response.user.uid)
                .collection("budgets")
                .doc("budget_1")
                .set({
                  accounts: [],
                  budgetTitle: "My First Budget",
                  groups: [
                    {
                      title: "Income",
                      categories: [
                        {
                          emoji: "💸",
                          title: "Income",
                          goal: [],
                          type: "save",
                        },
                      ],
                    },
                    {
                      title: "Investments & Savings",
                      categories: [
                        {
                          emoji: "🎭",
                          title: "NFTs",
                          goal: [],
                          type: "save",
                        },
                        {
                          emoji: "🌐",
                          title: "ENS Domains",
                          goal: [],
                          type: "save",
                        },
                        {
                          emoji: "💾",
                          title: "Savings",
                          goal: [],
                          type: "save",
                        },
                      ],
                    },
                    {
                      title: "Needs",
                      categories: [
                        {
                          emoji: "🏠",
                          title: "Rent",
                          goal: [],
                          type: "spend",
                        },
                        {
                          emoji: "🍜",
                          title: "Food",
                          goal: [],
                          type: "spend",
                        },
                        {
                          emoji: "🚆",
                          title: "Transportation",
                          goal: [],
                          type: "spend",
                        },
                        {
                          emoji: "⚡",
                          title: "Utilities",
                          goal: [],
                          type: "spend",
                        },
                      ],
                    },
                    {
                      title: "Wants",
                      categories: [
                        {
                          emoji: "✨",
                          title: "Personal Spending",
                          goal: [],
                          type: "spend",
                        },
                        {
                          emoji: "👕",
                          title: "Clothing",
                          goal: [],
                          type: "spend",
                        },
                        {
                          emoji: "✈️",
                          title: "Travel",
                          goal: [],
                          type: "spend",
                        },
                      ],
                    },
                    {
                      title: "Unexpected",
                      categories: [
                        {
                          emoji: "⛽",
                          title: "Gas Fees",
                          goal: [],
                          type: "spend",
                        },
                        {
                          emoji: "💣",
                          title: "Failed Transactions",
                          goal: [],
                          type: "spend",
                        },
                        {
                          emoji: "🥺",
                          title: "Stuff Happens",
                          goal: [],
                          type: "spend",
                        },
                        {
                          emoji: "💸",
                          title: "Transfers",
                          goal: [],
                          type: "spend",
                        },
                      ],
                    },
                    {
                      title: "Uncategorized",
                      categories: [
                        {
                          emoji: "⛽",
                          title: "Uncategorized",
                          goal: [],
                          type: "spend",
                        },
                      ],
                    },
                  ],
                });
            });
        }
        return response.user;
      });

    return result.newUser;
  };

  const loginWithLink = (email) => {
    var actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: "http://localhost:3000/authenticating",
      // This must be true.
      handleCodeInApp: true,
      iOS: {
        bundleId: "com.example.ios",
      },
      android: {
        packageName: "com.example.android",
        installApp: true,
        minimumVersion: "12",
      },
      dynamicLinkDomain: "luumen.page.link",
    };

    return firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        // ...
      })
      .catch((error) => {});
  };

  // const signUp = (email, password, referral) => {
  //   return firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(email, password)
  //     .then(async (response) => {
  //       setUser(response.user);
  //       await db
  //         .collection("users")
  //         .doc(response.user.uid)
  //         .set({
  //           email: email,
  //           createdDate: firebase.firestore.FieldValue.serverTimestamp(),
  //           betaAgreement: true,
  //           referrer: referral,
  //         })
  //         .then(async () => {
  //           await db
  //             .collection("users")
  //             .doc(response.user.uid)
  //             .collection("budgets")
  //             .doc("budget_1")
  //             .set({
  //               accounts: [],
  //               budgetTitle: "My First Budget",
  //               groups: [
  //                 {
  //                   title: "Income",
  //                   categories: [
  //                     {
  //                       emoji: "💸",
  //                       title: "Income",
  //                       goal: [],
  //                       type: "save",
  //                     },
  //                   ],
  //                 },
  //                 {
  //                   title: "Investments & Savings",
  //                   categories: [
  //                     {
  //                       emoji: "🎭",
  //                       title: "NFTs",
  //                       goal: [],
  //                       type: "save",
  //                     },
  //                     {
  //                       emoji: "🌐",
  //                       title: "ENS Domains",
  //                       goal: [],
  //                       type: "save",
  //                     },
  //                     {
  //                       emoji: "💾",
  //                       title: "Savings",
  //                       goal: [],
  //                       type: "save",
  //                     },
  //                   ],
  //                 },
  //                 {
  //                   title: "Needs",
  //                   categories: [
  //                     {
  //                       emoji: "🏠",
  //                       title: "Rent",
  //                       goal: [],
  //                       type: "save",
  //                     },
  //                     {
  //                       emoji: "🍜",
  //                       title: "Food",
  //                       goal: [],
  //                       type: "save",
  //                     },
  //                     {
  //                       emoji: "🚆",
  //                       title: "Transportation",
  //                       goal: [],
  //                       type: "save",
  //                     },
  //                     {
  //                       emoji: "⚡",
  //                       title: "Utilities",
  //                       goal: [],
  //                       type: "save",
  //                     },
  //                   ],
  //                 },
  //                 {
  //                   title: "Wants",
  //                   categories: [
  //                     {
  //                       emoji: "✨",
  //                       title: "Personal Spending",
  //                       goal: [],
  //                       type: "spend",
  //                     },
  //                     {
  //                       emoji: "👕",
  //                       title: "Clothing",
  //                       goal: [],
  //                       type: "spend",
  //                     },
  //                     {
  //                       emoji: "✈️",
  //                       title: "Travel",
  //                       goal: [],
  //                       type: "spend",
  //                     },
  //                   ],
  //                 },
  //                 {
  //                   title: "Unexpected",
  //                   categories: [
  //                     {
  //                       emoji: "⛽",
  //                       title: "Gas Fees",
  //                       goal: [],
  //                       type: "spend",
  //                     },
  //                     {
  //                       emoji: "💣",
  //                       title: "Failed Transactions",
  //                       goal: [],
  //                       type: "spend",
  //                     },
  //                     {
  //                       emoji: "🥺",
  //                       title: "Stuff Happens",
  //                       goal: [],
  //                       type: "spend",
  //                     },
  //                     {
  //                       emoji: "💸",
  //                       title: "Transfers",
  //                       goal: [],
  //                       type: "spend",
  //                     },
  //                   ],
  //                 },
  //               ],
  //             });
  //           return response.user;
  //         });
  //     });
  // };

  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const disableUser = () => {
    return firebase
      .auth()
      .updateUser(user.uid, {
        disabled: true,
      })
      .then(() => {
        return true;
      });
  };

  const logout = () => {
    return firebase.auth().signOut();
  };

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      setIsAuthenticating(false);
    });

    return () => unsub();
  }, []);

  const values = {
    user,
    isAuthenticating,
    login,
    loginWithMagic,
    loginWithLink,
    logout,
    sendPasswordResetEmail,
    disableUser,
  };

  // Provider component that wraps your app and makes auth object
  // ... available to any child component that calls useAuth().
  return (
    <AuthContext.Provider value={values}>
      {!isAuthenticating && children}
    </AuthContext.Provider>
  );
};
