import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Box,
  Flex,
  Text,
  useDisclosure,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { FaTag } from "react-icons/fa";
import { useAuth, db } from "../../firebase";

export const EditTxn = (props) => {
  const { onClose } = useDisclosure();
  const auth = useAuth();

  const [memo, setMemo] = useState("");
  const [payee, setPayee] = useState("");
  const [loading, setLoading] = useState(false);

  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const updateExpense = async () => {
    setLoading(true);
    let allTxns = [];
    props.accounts.forEach((acc) => {
      acc.txns.forEach((item, index) => {
        if (item.txId === props.txn.txId) {
          item.memo = memo;
          item.payee = payee;
        }
      });
      allTxns.push(...acc.txns);
    });

    await db
      .collection("users")
      .doc(auth.user.uid)
      .collection("budgets")
      .doc("budget_1")
      .update({
        accounts: props.accounts,
      })
      .then(() => {
        props.updateData(allTxns);
        setLoading(false);
        props.showModal();
      })
      .catch((err) => {
        alert(err);
        setLoading(false);
      });
  };

  const renderInputs = () => {
    return (
      <ModalBody pb={6}>
        <FormControl>
          <FormLabel>Add Payee</FormLabel>
          <Input
            borderRadius={0}
            border={"2px"}
            borderColor={"gray.100"}
            _focus={{ outline: "blue.300" }}
            _hover={{ borderColor: "blue.300" }}
            _active={{ borderColor: "blue.300" }}
            size="lg"
            ref={initialRef}
            onChange={(e) => {
              setPayee(e.target.value);
            }}
            placeholder="Landlord"
          />
        </FormControl>
        <FormControl my={4}>
          <FormLabel>Add Memo</FormLabel>
          <Input
            borderRadius={0}
            border={"2px"}
            borderColor={"gray.100"}
            _focus={{ outline: "blue.300" }}
            _hover={{ borderColor: "blue.300" }}
            _active={{ borderColor: "blue.300" }}
            size="lg"
            ref={initialRef}
            onChange={(e) => {
              setMemo(e.target.value);
            }}
            placeholder="Had to pay rent"
          />
        </FormControl>
      </ModalBody>
    );
  };

  const renderLoading = () => {
    return (
      <ModalBody>
        <Center>
          <Spinner size="xl" />
        </Center>
      </ModalBody>
    );
  };

  return (
    <>
      {/* <Button size="sm" colorScheme="orange" px="5" onClick={onOpen}>
        Create Account
      </Button> */}

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={props.isOpen}
        onClose={onClose}
        isCentered
        size="lg"
      >
        <ModalOverlay />
        <ModalContent
          borderRadius="0"
          borderColor="gray.100"
          border="2px"
          color="gray.700"
          py="2"
        >
          <ModalHeader>
            <Flex alignItems="center">
              <Box
                borderRadius="0"
                border="solid"
                borderColor="gray.100"
                bg="blue.100"
                p="4"
                mr="4"
              >
                <FaTag style={{ opacity: 0.5 }} />
              </Box>
              <Text fontSize="2xl" fontWeight="bold">
                Update an Expense
              </Text>
            </Flex>
          </ModalHeader>

          {renderInputs()}

          <ModalFooter>
            <Button
              variant={"luumen"}
              bg="gray.100"
              mr={3}
              size="lg"
              onClick={() => {
                props.showModal();
              }}
            >
              Cancel
            </Button>
            <Button
              variant={"luumen"}
              size="lg"
              px="10"
              isLoading={loading}
              onClick={() => {
                updateExpense();
              }}
            >
              Update Expense
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
