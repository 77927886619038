import { atom } from "recoil";

export const filterState = atom({
  key: "filterState",
  default: "clear",
});

export const count = atom({
  key: "count",
  default: 3,
});

export const marketData = atom({
  key: "marketData",
  default: [],
});

export const userMetaData = atom({
  key: "userMetaData",
  default: {
    subscription: { tier: 0, active: false },
  },
});

export const tierData = atom({
  key: "tierData",
  default: {
    0: {
      plan: "Common",
      connections: 1,
    },
    1: {
      plan: "Rare",
      connections: 5,
    },
    2: {
      plan: "Epic",
      connections: 20,
    },
    3: {
      plan: "Legendary",
      connections: 50,
    },
    9: {
      plan: "Mythical",
      connections: 5,
    },
  },
});
