import React, { useState, useEffect } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  useDisclosure,
  Button,
  Box,
  Heading,
  Select,
  Center,
  FormControl,
  FormLabel,
  Input,
  useToast,
  NumberInput,
  NumberInputField,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useAuth, db } from "../../firebase";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

const defaultEmojis = [
  "✨",
  "🎈",
  "🎭",
  "📀",
  "🃏",
  "🔮",
  "🎪",
  "💵",
  "🐱",
  "🧸",
  "🐤",
  "💼",
];

const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};

export const CategoryDrawer = (props) => {
  const { onClose } = useDisclosure();
  const auth = useAuth();
  const toast = useToast();
  const [step, setStep] = useState(props.step);
  const [group, setGroup] = useState("Fun Fund");
  const [category, setCategory] = useState("");
  const [goal, setGoal] = useState("");
  const [cryptoType, setCryptoType] = useState("btc");
  const [type, setType] = useState("spend");
  const [emoji, setEmoji] = useState(
    defaultEmojis[getRandomInt(defaultEmojis.length)]
  );
  const [showPicker, setShowPicker] = useState(false);
  const [createGroup, setCreateGroup] = useState(false);

  const updateCategory = async () => {
    let oldGroups;
    //let updatedCats = [category];

    if (group !== "" && category !== "") {
      await db
        .collection("users")
        .doc(auth.user.uid)
        .collection("budgets")
        .doc("budget_1")
        .get()
        .then((doc) => {
          oldGroups = doc.data().groups;
        });

      if (createGroup) {
        // This means we need to create a Group, so let's structure the data
        let newObj = {
          title: group,
          categories: [
            {
              title: category,
              goal: [
                {
                  cryptoType: cryptoType,
                  amount: goal,
                },
              ],
              type: type,
            },
          ],
        };

        oldGroups = [...oldGroups, newObj];
      } else {
        let obj = oldGroups.find((item) => item.title == group);
        let index = oldGroups.indexOf(obj);

        let newCategory = {
          title: category,
          goal: [
            {
              cryptoType: cryptoType,
              amount: goal,
            },
          ],
          type: type,
          emoji: emoji,
        };

        oldGroups[index].categories = [
          ...oldGroups[index].categories,
          newCategory,
        ];
      }

      await db
        .collection("users")
        .doc(auth.user.uid)
        .collection("budgets")
        .doc("budget_1")
        .update({
          groups: oldGroups,
        })
        .then(() => {
          props.toggleDrawer(false);
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      toast({
        title: "We've detected an empty group or category",
        description:
          "Please ensure that your category or group title is not empty",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const renderContent = () => {
    switch (step) {
      case 0:
        return renderIntro();
      case 1:
        return renderMain();
      case 2:
        return renderSub();
      default:
        return renderIntro();
    }
  };

  const renderIntro = () => {
    return (
      <Box>
        <Center
          cursor="pointer"
          width="100%"
          height="250px"
          bg="blue.300"
          mb="25px"
          borderRadius="0"
          borderColor="gray.700"
          border="2px"
          color="gray.700"
          py="2"
          transition="0.2s"
          sx={{ boxShadow: "0px 0px 0px #383A48" }}
          _hover={{
            transform: "translate(-4px, -4px)",
            boxShadow: "8px 8px 0px #383A48",
          }}
          _focus={{ boxShadow: "8px 8px 0px #383A48 !important" }}
          _active={{ boxShadow: "8px 8px 0px #383A48" }}
          onClick={() => {
            setCreateGroup(true);
            setStep(1);
          }}
        >
          <Heading>Create Group</Heading>
        </Center>
        <Center
          cursor="pointer"
          width="100%"
          height="250px"
          bg="blue.300"
          borderRadius="0"
          borderColor="gray.700"
          border="2px"
          color="gray.700"
          py="2"
          transition="0.2s"
          sx={{ boxShadow: "0px 0px 0px #383A48" }}
          _hover={{
            transform: "translate(-4px, -4px)",
            boxShadow: "8px 8px 0px #383A48",
          }}
          _focus={{ boxShadow: "8px 8px 0px #383A48 !important" }}
          _active={{ boxShadow: "8px 8px 0px #383A48" }}
          onClick={() => {
            setStep(2);
          }}
        >
          <Heading>Create Category</Heading>
        </Center>
      </Box>
    );
  };

  const renderMain = () => {
    return (
      <>
        <Heading mb="3" size="lg">
          Create a Group
        </Heading>
        <FormControl mb="5">
          <FormLabel>Group Name</FormLabel>
          <Input
            variant="with-shadow-sm"
            size="lg"
            value={group}
            onChange={(e) => {
              setGroup(e.target.value);
            }}
            placeholder="Fun Fund"
          />
        </FormControl>
        <Button
          disabled={group === ""}
          variant="luumen"
          w={"100%"}
          onClick={() => {
            setCreateGroup(true);
            setStep(2);
          }}
        >
          Next
        </Button>
      </>
    );
  };

  const renderSub = () => {
    return (
      <>
        {showPicker && (
          <Picker
            title=""
            emoji=""
            emojiSize={20}
            native
            style={{
              position: "absolute",
              top: "120px",
              left: "24px",
              zIndex: "1000",
            }}
            onSelect={(e) => {
              setEmoji(e.native);
              setShowPicker(false);
            }}
          />
        )}
        <Flex alignItems="center" mb="3">
          <Center
            cursor={"pointer"}
            onClick={() => {
              setShowPicker(!showPicker);
            }}
            h={45}
            w={45}
            bg={"gray.50"}
            borderRadius={"5px"}
          >
            <Text
              fontSize="xl"
              _hover={{ transform: "scale(1.2)" }}
              transition="0.2s"
            >
              {emoji}
            </Text>
          </Center>
          <Heading ml="2" size="lg">
            Create a Category
          </Heading>
        </Flex>
        <FormControl mb="4">
          <FormLabel>Add Category</FormLabel>
          <Input
            size="lg"
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            placeholder="Rent"
          />
        </FormControl>

        <FormControl my={5}>
          <FormLabel>Type of Category</FormLabel>
          <Select
            size="lg"
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <option value="spend">Spend</option>
            <option value="save">Save</option>
          </Select>
        </FormControl>

        {createGroup === false && (
          <FormControl my={5}>
            <FormLabel>Group</FormLabel>
            <Select
              size="lg"
              value={group}
              onChange={(e) => {
                setGroup(e.target.value);
              }}
            >
              {props.groups.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        )}
        <Button
          variant="luumen"
          w={"full"}
          onClick={() => {
            updateCategory();
          }}
        >
          Create Category
        </Button>
      </>
    );
  };

  return (
    <>
      <Drawer
        isOpen={props.showCategoryDrawer}
        placement="right"
        onClose={onClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton
            onClick={() => {
              props.toggleDrawer(false);
            }}
          />
          <DrawerHeader>Groups & Categories</DrawerHeader>

          <DrawerBody>{renderContent()}</DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
