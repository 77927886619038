import React from "react";
import { Tables } from "../Table/Table";

export const Transactions = (props) => {
  return (
    <>
      <Tables selectedAccount={props.account} />
    </>
  );
};
