import {
  Center,
  Heading,
  Icon,
  Stack,
  Text,
  Button,
  Flex,
  Switch,
  FormControl,
  SlideFade,
  chakra,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaCampground, FaShieldAlt, FaCrown } from "react-icons/fa";
import firebase from "firebase";
import { loadStripe } from "@stripe/stripe-js";
import { userMetaData } from "../../Atoms";
import { useRecoilState } from "recoil";

export const Pricing = () => {
  const [subscriptionType, setSubscriptionType] = useState(0);
  const [userMetaState] = useRecoilState(userMetaData);
  const [rare, setRare] = useState(false);
  const [epic, setEpic] = useState(false);
  const [legendary, setLegendary] = useState(false);

  const openCheckout = async (priceId) => {
    //firebase.functions().useEmulator("localhost", 5001);
    const stripe = await loadStripe(
      "pk_live_51KR3uYHGwxPqpl37E4U1KrnQy87YZb3mG8zBChLfWkg7mFydOKF3ylV5Oly7Eq4lC3mf8ePbUnhbmL7fhaRfIkoN00l74ULJq6"
    );
    if (userMetaState.subscription.active) {
      let portal = firebase.functions().httpsCallable("stripePortal");

      portal({
        customerId: userMetaState.subscription.stripeId,
      }).then((result) => {
        window.location.href = result.data.sessionUrl;
      });
    } else {
      let checkout = firebase.functions().httpsCallable("stripeCheckout");

      checkout({
        customerId: userMetaState.subscription.stripeId,
        products: [
          {
            price: priceId,
            quantity: 1,
          },
        ],
      }).then((result) => {
        stripe.redirectToCheckout({
          sessionId: result.data.session.id,
        });
      });
    }
  };

  return (
    <Center minH={"96vh"} flexDir={"column"}>
      <Heading>Choose a plan</Heading>
      <Text my={3}>
        Super simple pricing. You get two months free with an annual
        subscription.
      </Text>
      <FormControl
        display="flex"
        alignItems="center"
        justifyContent={"center"}
        mb={12}
      >
        <Text>Monthly</Text>
        <Switch
          mx={4}
          onChange={(e) => {
            setSubscriptionType(e.target.checked ? 1 : 0);
          }}
        />
        <Text fontWeight={700}>Annual</Text>
      </FormControl>

      <Flex w={"100%"} alignItems={"center"} justifyContent={"space-evenly"}>
        <SlideFade
          direction="bottom"
          in={true}
          transition={{
            enter: { duration: 0.3, delay: 0.1 },
          }}
        >
          <Flex
            maxW={"350px"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius="5px"
            backgroundColor="white"
            border="2px"
            borderColor="blue.100"
            p={3}
            textAlign={"center"}
            _hover={{ borderColor: "blue.500" }}
            transition={"200ms"}
          >
            <Icon as={FaCampground} opacity={0.2} w={"40px"} h={"40px"} />
            <Heading py={3} fontSize={"2xl"}>
              Rare
            </Heading>
            <Text>
              Perfect for the crypto-adventurer who wants to build a better
              financial future for themselves.
            </Text>
            <Heading py={5}>
              ${subscriptionType === 0 ? "10" : "100"}
              <chakra.span fontSize={"lg"}>
                {subscriptionType === 0 ? "/month" : "/year"}
              </chakra.span>
            </Heading>

            <Stack textAlign={"center"}>
              <Text>5 Account Connections</Text>
              <Text>Automated Portfolio Tracking</Text>
              <Text>Financial Planning Tools & Budgeting</Text>
              <Text>NFT Performance Tracking</Text>
              <Text>Transaction Tracking </Text>
              <Text>Cost Basis Accounting for Taxes</Text>
              <Text>Export Tax Reports</Text>
              <Text>Basic Support + Community Access</Text>
            </Stack>
            <Button
              variant={"luumen"}
              isLoading={rare}
              w={"full"}
              mt={10}
              onClick={async () => {
                setRare(true);
                openCheckout(
                  subscriptionType === 0
                    ? "price_1KSHqiHGwxPqpl37Ox5MzLoH"
                    : "price_1KSHqiHGwxPqpl37Pbt5CBdA"
                );
              }}
            >
              Upgrade
            </Button>
          </Flex>
        </SlideFade>
        <SlideFade
          direction="bottom"
          in={true}
          transition={{
            enter: { duration: 0.3, delay: 0.25 },
          }}
        >
          <Flex
            maxW={"350px"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius="5px"
            backgroundColor="white"
            border="2px"
            borderColor="purple.100"
            p={3}
            textAlign={"center"}
            _hover={{ borderColor: "purple.500" }}
            transition={"200ms"}
          >
            <Icon as={FaShieldAlt} opacity={0.2} w={"40px"} h={"40px"} />
            <Heading py={3} fontSize={"2xl"}>
              Epic
            </Heading>
            <Text>
              For those who are managing their crypto finances at scale with
              plenty of room for growth.
            </Text>
            <Heading py={5}>
              ${subscriptionType === 0 ? "30" : "300"}
              <chakra.span fontSize={"lg"}>
                {subscriptionType === 0 ? "/month" : "/year"}
              </chakra.span>
            </Heading>

            <Stack textAlign={"center"}>
              <Text>20 Account Connections</Text>
              <Text>Automated Portfolio Tracking</Text>
              <Text>Financial Planning Tools & Budgeting</Text>
              <Text>NFT Performance Tracking</Text>
              <Text>Transaction Tracking </Text>
              <Text>Cost Basis Accounting for Taxes</Text>
              <Text>Export Tax Reports</Text>
              <Text>Premium Support + Community Access</Text>
            </Stack>
            <Button
              variant={"luumen"}
              isLoading={epic}
              w={"full"}
              mt={10}
              _hover={{
                backgroundColor: "purple.100",
                borderColor: "purple.500",
              }}
              onClick={() => {
                setEpic(true);
                openCheckout(
                  subscriptionType === 0
                    ? "price_1KSHqfHGwxPqpl37utoqS89n"
                    : "price_1KSHqfHGwxPqpl37ZnnWx3Dp"
                );
              }}
            >
              Upgrade
            </Button>
          </Flex>
        </SlideFade>
        <SlideFade
          direction="bottom"
          in={true}
          transition={{
            enter: { duration: 0.2, delay: 0.35 },
          }}
        >
          <Flex
            maxW={"350px"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius="5px"
            backgroundColor="white"
            border="2px"
            borderColor="orange.100"
            p={3}
            textAlign={"center"}
            _hover={{ borderColor: "yellow.400" }}
            transition={"200ms"}
          >
            <Icon as={FaCrown} opacity={0.2} w={"40px"} h={"40px"} />
            <Heading py={3} fontSize={"2xl"}>
              Legendary
            </Heading>
            <Text>
              Great for legendary crypto users who are taking full advantage of
              decentralization and personal finances.
            </Text>
            <Heading py={5}>
              ${subscriptionType === 0 ? "60" : "600"}
              <chakra.span fontSize={"lg"}>
                {subscriptionType === 0 ? "/month" : "/year"}
              </chakra.span>
            </Heading>

            <Stack textAlign={"center"}>
              <Text>50 Account Connections</Text>
              <Text>Automated Portfolio Tracking</Text>
              <Text>Financial Planning Tools & Budgeting</Text>
              <Text>NFT Performance Tracking</Text>
              <Text>Transaction Tracking </Text>
              <Text>Cost Basis Accounting for Taxes</Text>
              <Text>Export Tax Reports</Text>
              <Text>Premium Support + Community Access</Text>
            </Stack>
            <Button
              variant={"luumen"}
              isLoading={legendary}
              _hover={{
                backgroundColor: "yellow.100",
                borderColor: "yellow.400",
              }}
              w={"full"}
              mt={10}
              onClick={() => {
                setLegendary(true);
                openCheckout(
                  subscriptionType === 0
                    ? "price_1KSHqaHGwxPqpl37HnnvoQYV"
                    : "price_1KSHqaHGwxPqpl37Z9eYvBmT"
                );
              }}
            >
              Upgrade
            </Button>
          </Flex>
        </SlideFade>
      </Flex>
    </Center>
  );
};
