import React, { useState, useEffect } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  useDisclosure,
  Text,
  Button,
  Box,
  useEditableControls,
  Stack,
  useToast,
  Divider,
  Flex,
  Editable,
  EditableInput,
  EditablePreview,
} from "@chakra-ui/react";
import { BsThreeDots } from "react-icons/bs";
import { BiCopy } from "react-icons/bi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useAuth, db } from "../../firebase";
import firebase from "firebase";

import axios from "axios";

export const AccountManagement = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const auth = useAuth();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  function EditableControls() {
    const { isEditing } = useEditableControls();

    return isEditing ? (
      <Text fontStyle={"italic"} color={"gray.400"} fontSize={"sm"}>
        Press Enter to save
      </Text>
    ) : (
      ""
    );
  }

  const updateAccountTitle = async (acc, title) => {
    if (title !== acc.accountTitle && title !== "") {
      console.log(acc);

      let copy = JSON.parse(JSON.stringify(acc));

      copy.accountTitle = title;

      await db
        .collection("users")
        .doc(auth.user.uid)
        .collection("budgets")
        .doc("budget_1")
        .update({
          accounts: firebase.firestore.FieldValue.arrayRemove(acc),
        })
        .then(async () => {
          await db
            .collection("users")
            .doc(auth.user.uid)
            .collection("budgets")
            .doc("budget_1")
            .update({
              accounts: firebase.firestore.FieldValue.arrayUnion(copy),
            })
            .catch((err) => {
              toast({
                title: "We've encountered an error...",
                description: err.message,
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            });
        })
        .catch((err) => {
          toast({
            title: "We've encountered an error...",
            description: err.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };

  const removeAccount = async (acc) => {
    await props.vUser.accounts
      .remove(acc.uid)
      .then(async () => {
        await db
          .collection("users")
          .doc(auth.user.uid)
          .collection("budgets")
          .doc("budget_1")
          .update({
            accounts: firebase.firestore.FieldValue.arrayRemove(acc),
          });
      })
      .catch((err) => {
        toast({
          title: "We've encountered an error...",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Button
        variant={"unstyled"}
        fontSize={"2xl"}
        outline={"none"}
        _focus={{ outline: "none" }}
        _hover={{ color: "blue.300" }}
        onClick={onOpen}
      >
        <BsThreeDots style={{ display: "inline" }} />
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Account Management</DrawerHeader>

          <DrawerBody>
            <Button
              borderRadius={0}
              bg={"white"}
              border={"2px"}
              borderColor={"gray.100"}
              w={"100%"}
              _hover={{ bg: "blue.100", borderColor: "blue.300" }}
              disabled={props.accounts.length === 0}
              loadingText="Syncing"
              isLoading={loading}
              onClick={() => {
                setLoading(true);
                const sync = firebase.functions().httpsCallable("syncAccount");

                sync({ uid: auth.user.uid })
                  .then((res) => {
                    toast({
                      title: "Accounts have been synced",
                      status: "info",
                      duration: 3000,
                      isClosable: true,
                    });
                    setLoading(false);
                  })
                  .catch((err) => {
                    toast({
                      title: "We've encountered an error syncing your accounts",
                      description:
                        "Please try again, if this issue is persistent please reach out to support.",
                      status: "warning",
                      duration: 3000,
                      isClosable: true,
                    });
                    setLoading(false);
                  });

                // console.log("sync accounts");
              }}
            >
              Sync Accounts
            </Button>

            <Divider my={3} />

            <Text fontStyle={"italic"} color={"gray.400"} fontSize={"sm"}>
              Click the account title to edit
            </Text>

            <Stack mt={3} spacing={3}>
              {props.accounts.map((account, index) => {
                return (
                  <Box
                    cursor={"pointer"}
                    key={index}
                    role="group"
                    w={"100%"}
                    bg="white"
                    border="2px"
                    borderColor="gray.100"
                    _hover={{
                      borderColor: "blue.300",
                    }}
                    transition={"0.2s"}
                  >
                    <Flex
                      px="3"
                      py="3"
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Box>
                        {/* <Heading size="md">{account.accountTitle}</Heading> */}
                        <Editable
                          defaultValue={account.accountTitle}
                          onSubmit={(e) => {
                            updateAccountTitle(account, e);
                          }}
                          _focus={{ outline: "none", boxShadow: "none" }}
                        >
                          <EditablePreview fontSize={"xl"} fontWeight={"700"} />
                          <EditableInput
                            _focus={{
                              outline: "none",
                              boxShadow: "none",
                              fontStyle: "italic",
                            }}
                            fontSize={"xl"}
                            fontWeight={"700"}
                          />
                          <EditableControls />
                        </Editable>
                      </Box>
                      <Button
                        _hover={{ color: "#FA2007" }}
                        variant={"unstyled"}
                        minW={0}
                        p={0}
                        pl={2}
                        onClick={() => {
                          removeAccount(account);
                        }}
                      >
                        <RiDeleteBin5Line
                          style={{ display: "inline", fontSize: "18px" }}
                        />
                      </Button>
                    </Flex>
                    <Flex
                      bg="gray.50"
                      transition={"0.2s"}
                      _groupHover={{ bg: "blue.100" }}
                      px="3"
                      py="1"
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      onClick={() => {
                        navigator.clipboard
                          .writeText(account.address)
                          .then(() => {
                            toast({
                              title: "Copied!",
                              status: "info",
                              duration: 1000,
                              isClosable: true,
                              position: "bottom-right",
                            });
                          });
                      }}
                    >
                      <Text fontSize="sm" fontWeight={"semibold"}>
                        {account.address}
                      </Text>
                      <Button
                        _hover={{ color: "#0765F2" }}
                        variant={"unstyled"}
                        minW={0}
                        p={0}
                      >
                        <BiCopy
                          style={{ display: "inline", fontSize: "18px" }}
                        />
                      </Button>
                    </Flex>
                  </Box>
                );
              })}
            </Stack>

            {/* <CreateAccount userTxns={props.userTxns} /> */}
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
