import React, { useState } from "react";
import {
  Stack,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Flex,
  Text,
  Box,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { PasswordField } from "../Inputs/PasswordInput";
import { useAuth } from "../../firebase";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const toast = useToast();
  const params = new URLSearchParams(window.location.search);
  const ref = params.get("refer");

  const loginMagic = async () => {
    setLoading(true);
    try {
      await auth.loginWithMagic(email, ref).then((res) => {
        setLoading(false);
        if (res) navigate("/onboarding");
        else navigate("/");
      });
    } catch (error) {
      toast({
        title: "Holy shit stick, batman! We've found an error!",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <Flex
      h="100vh"
      justifyContent="center"
      alignItems="center"
      bgGradient="linear(150.28deg, rgba(189, 147, 249, 0.52) 4.17%, rgba(147, 244, 171, 0.26) 100%)"
    >
      <Box w="sm">
        <Stack spacing="3">
          <Heading>Login</Heading>
          <Text align="left" maxW="md" fontWeight="medium">
            <Text mr="1" as="span">
              Don't have an account?
            </Text>
            <Text>No worries, we create one for you upon login</Text>
          </Text>
          <FormControl id="email">
            <FormLabel>Email address</FormLabel>
            <Input
              size="lg"
              border={"2px"}
              borderColor={"gray.100"}
              _focus={{ outline: "blue.300" }}
              _hover={{ borderColor: "blue.300" }}
              _active={{ borderColor: "blue.300" }}
              name="email"
              type="email"
              autoComplete="email"
              required
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </FormControl>

          <Button
            variant={"luumen"}
            w="100%"
            size="lg"
            fontSize="md"
            onClick={loginMagic} // eslint-disable-line
            isLoading={loading}
            loadingText={"Sending Link..."}
          >
            Login with Magic
          </Button>
        </Stack>
      </Box>
    </Flex>
  );
};
