import {
  Box,
  Center,
  Heading,
  Text,
  Flex,
  Icon,
  Spinner,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React from "react";
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";

export const Transactions = (props) => {
  return (
    <Box>
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Heading fontSize={"xl"}>Recent Transactions</Heading>
        <Link to="/transactions">
          <Text
            fontWeight={700}
            color={"blue.400"}
            transition={"200ms"}
            _hover={{ textDecor: "underline", color: "blue.600" }}
          >
            View All →
          </Text>
        </Link>
      </Flex>

      {props.loading && (
        <Center h={"405px"}>
          <Spinner
            thickness="4px"
            speed="0.75s"
            emptyColor="gray.100"
            color="blue.300"
            size="xl"
          />
        </Center>
      )}

      {!props.loading && props.transactions.length === 0 && (
        <Center flexDir="column" opacity={0.2}>
          <Heading>No transactions detected...</Heading>
          <Text mt={5} maxW={750} textAlign={"center"}>
            If you recently added an account, it may take a few minutes to fetch
            all of your transactions. Once your account is successfully synced,
            your transactions will show up here.
          </Text>
        </Center>
      )}

      {!props.loading &&
        props.transactions.length !== 0 &&
        props.transactions.slice(0, 5).map((item, index) => {
          return (
            <Flex
              key={index}
              role="group"
              w={"100%"}
              alignItems={"center"}
              justifyContent={"space-between"}
              borderRadius={"3px"}
              p={1}
              px={3}
              bg="white"
              border="2px"
              borderColor="gray.100"
              _hover={{
                borderColor: "blue.300",
              }}
              transition={"0.2s"}
              mt={3}
            >
              <Flex alignItems={"center"}>
                <Center
                  borderRadius={3}
                  p={2}
                  bg={item.value > 0 ? "green.100" : "red.100"}
                >
                  <Icon
                    sx={{ transform: "rotate(45deg)" }}
                    as={item.value > 0 ? BiDownArrowAlt : BiUpArrowAlt}
                    w={7}
                    h={7}
                  />
                </Center>
                <Box p="3">
                  <Heading size="md" mb="1">
                    {item.account || "Test"}
                  </Heading>
                  <Text fontSize="sm" fontStyle="italic">
                    {new Date(item.date).toLocaleDateString()}
                  </Text>
                </Box>
              </Flex>
              <Box textAlign={"right"}>
                <Text fontSize="sm" fontWeight={"semibold"}>
                  {Number(item.value)} {item.cryptoType}
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight={"semibold"}
                  color={item.fiat_at_purchase ? "inherit" : "gray.100"}
                >
                  {item.fiat_at_purchase
                    ? Number(item.fiat_at_purchase.amount).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                          maxFractionDigits: 2,
                        }
                      )
                    : "No price available"}
                </Text>
              </Box>
            </Flex>
          );
        })}
    </Box>
  );
};
