import React, { useState, useEffect } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  useDisclosure,
  Button,
  Flex,
  Text,
  chakra,
  FormControl,
  FormLabel,
  Heading,
  useToast,
  Input,
  Stack,
  Box,
  Select,
} from "@chakra-ui/react";
import { useAuth, db } from "../../firebase";

export const EditGoalDrawer = (props) => {
  const { onClose } = useDisclosure();
  const auth = useAuth();
  const toast = useToast();
  const [amount, setAmount] = useState();
  const [type, setType] = useState(props.selected.type);
  const [group, setGroup] = useState("");
  const [showNewInput, setShowNewInput] = useState(false);

  const updateGoal = async () => {
    let oldGroups;
    //let updatedCats = [category];

    await db
      .collection("users")
      .doc(auth.user.uid)
      .collection("budgets")
      .doc("budget_1")
      .get()
      .then((doc) => {
        oldGroups = doc.data().groups;
      });

    let obj = oldGroups.find((item) => item.title == props.group);
    let index = oldGroups.indexOf(obj);
    let updatedObj = oldGroups[index].categories.find(
      (item) => item.title == props.selected.title
    );

    let catIndex = oldGroups[index].categories.indexOf(updatedObj);

    let newCategory = {
      emoji: props.selected.emoji,
      title: props.selected.title,
      goal: [{ amount: amount }],
      type: type || props.selected.type,
    };

    oldGroups[index].categories[catIndex] = newCategory;

    await db
      .collection("users")
      .doc(auth.user.uid)
      .collection("budgets")
      .doc("budget_1")
      .update({
        groups: oldGroups,
      })
      .then(() => {
        props.toggleDrawer(false);
      })
      .catch((err) => {
        toast({
          title: "Oops!",
          description: err.message,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  };

  const removeGoal = async (i) => {
    let oldGroups;
    //let updatedCats = [category];

    await db
      .collection("users")
      .doc(auth.user.uid)
      .collection("budgets")
      .doc("budget_1")
      .get()
      .then((doc) => {
        oldGroups = doc.data().groups;
      });

    let obj = oldGroups.find((item) => item.title == props.group);
    let category = obj.categories;
    let goalToDelete = category.find(
      (item) => item.title === props.selected.title
    );

    let removeGoal = goalToDelete.goal.findIndex(
      (item) => item.cryptoType === i.cryptoType
    );

    goalToDelete.goal.splice(removeGoal, 1);

    await db
      .collection("users")
      .doc(auth.user.uid)
      .collection("budgets")
      .doc("budget_1")
      .update({
        groups: oldGroups,
      })
      .then(() => {
        props.toggleDrawer(false);
      })
      .catch((err) => {
        toast({
          title: "Oops!",
          description: err.message,
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Drawer
        isOpen={props.showGoalDrawer}
        placement="right"
        onClose={onClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton
            onClick={() => {
              props.toggleDrawer(false);
            }}
          />
          <DrawerHeader>Goal Management</DrawerHeader>

          <DrawerBody>
            <>
              <Flex>
                <Text mb="3" fontSize="2xl" fontWeight="semibold">
                  Update
                </Text>
                <Text mb="3" ml="2" mr="2" fontSize="2xl">
                  {props.selected?.emoji}
                </Text>
                <Text mb="3" fontSize="2xl" fontWeight="semibold">
                  {props.selected?.title}
                </Text>
              </Flex>

              <hr style={{ margin: "10px 0" }} />

              <Stack>
                {props.selected.goal.map((item, index) => {
                  return (
                    <Box key={index}>
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Text
                          fontSize={"lg"}
                          fontWeight={"bold"}
                          cursor={"pointer"}
                          onClick={() => {
                            setAmount(item.amount);
                            setShowNewInput(true);
                          }}
                        >
                          ${Number(item.amount).toLocaleString()}{" "}
                          <chakra.span fontSize={"sm"} cursor={"pointer"}>
                            Click to Edit
                          </chakra.span>
                        </Text>

                        <Text
                          fontSize={"sm"}
                          color={"red.500"}
                          cursor={"pointer"}
                          onClick={() => {
                            removeGoal(item);
                          }}
                        >
                          Remove
                        </Text>
                      </Flex>
                      <hr style={{ margin: "10px 0" }} />
                    </Box>
                  );
                })}
              </Stack>

              {showNewInput && (
                <>
                  <Heading mt="5" mb="2" fontSize={"lg"}>
                    Update Fiat goal
                  </Heading>
                  <FormControl mb="4">
                    <FormLabel>Set a Goal</FormLabel>
                    <Input
                      size="lg"
                      border={"2px"}
                      borderColor={"gray.100"}
                      _focus={{ outline: "blue.300" }}
                      _hover={{ borderColor: "blue.300" }}
                      _active={{ borderColor: "blue.300" }}
                      type="number"
                      placeholder="$250.00"
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl mb="4">
                    <FormLabel>Type of Goal</FormLabel>
                    <Select
                      borderRadius={"5px"}
                      bg={"white"}
                      border={"2px"}
                      borderColor={"gray.100"}
                      _hover={{ bg: "blue.100", borderColor: "blue.300" }}
                      mb="5"
                      size="lg"
                      _focus={{ outline: "none" }}
                      _active={{ outline: "none" }}
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    >
                      <option value={"spend"}>Spend</option>
                      <option value={"save"}>Save</option>
                    </Select>
                  </FormControl>
                </>
              )}
              <Flex>
                {showNewInput && (
                  <>
                    <Button
                      mr={4}
                      w={"40%"}
                      variant={"luumen"}
                      size={"lg"}
                      bg={"red.100"}
                      borderColor={"red.200"}
                      _hover={{ bg: "red.200", borderColor: "red.500" }}
                      onClick={() => {
                        setShowNewInput(!showNewInput);
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant={"luumen"}
                      size={"lg"}
                      w={"100%"}
                      onClick={() => {
                        if (showNewInput) {
                          updateGoal();
                        } else {
                          setShowNewInput(!showNewInput);
                        }
                      }}
                    >
                      Update Goal
                    </Button>
                  </>
                )}
              </Flex>
            </>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
