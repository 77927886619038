import React, { useState, useEffect } from "react";
import { Tr, Td, Flex, Tooltip, Text, useToast } from "@chakra-ui/react";
import { CategoryDrawer } from "../Modal/CategoryDrawer";
import { Dropdown } from "./Dropdown";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

export const TableRow = (props) => {
  const [showCategoryDrawer, setShowCategoryDrawer] = useState(false);
  const [step, setStep] = useState(1);
  const toast = useToast();

  const toggleDrawer = (data, s) => {
    setShowCategoryDrawer(data);
    setStep(s);
  };

  const updateData = (data) => {
    props.updateData(data.sort((a, b) => (a.date > b.date ? -1 : 1)));
  };

  const explorerLink = (txn) => {
    switch (txn.cryptoType) {
      case "ETH":
        return `https://etherscan.io/tx/${txn.txId}`;
      case "BTC":
        return `https://www.blockchain.com/btc/tx/${txn.txId}`;
      case "DOGE":
        return `https://dogechain.info/tx/${txn.txId}`;

      default:
        return null;
    }
  };

  return (
    <Tr>
      <Td cursor="default">
        <Tooltip
          label={new Date(props.date * 1000).toLocaleTimeString()}
          aria-label="A tooltip"
          borderRadius="0"
        >
          {new Date(props.date).toLocaleDateString()}
        </Tooltip>
      </Td>
      <Td>
        {showCategoryDrawer && (
          <CategoryDrawer
            categories={props.userCategories}
            showCategoryDrawer
            toggleDrawer={toggleDrawer}
            groups={props.groups}
            step={step}
          />
        )}

        {props.disableCategory !== true ? (
          <Dropdown
            accounts={props.accounts}
            categories={props.userCategories}
            category={props.category}
            emoji={props.emoji}
            txn={props.txn}
            updateData={updateData}
            allTxns={props.allTxns}
            toggleDrawer={toggleDrawer}
          ></Dropdown>
        ) : (
          <Text py={3}>{props.category}</Text>
        )}
      </Td>
      <Td
        onClick={() => {
          if (props.filteredAccount) {
            props.selectTxn(
              props.allTxns.findIndex(
                (e) => e.id === props.filteredAccount.txns[props.txnIndex].id
              )
            );
          } else {
            props.selectTxn(props.txnIndex);
          }
        }}
        _hover={{ cursor: "pointer" }}
      >
        {props.payee || "Add Payee"}
      </Td>
      <Td>{props.memo || "Add Memo"}</Td>
      <Td
        _hover={{ cursor: "pointer", color: "blue.300" }}
        onClick={() => {
          if (explorerLink(props.txn) !== null) {
            window.open(explorerLink(props.txn), "_blank");
          } else {
            navigator.clipboard.writeText(props.transaction_id).then(() => {
              toast({
                title: "Copied!",
                status: "info",
                duration: 1000,
                isClosable: true,
                position: "bottom-right",
              });
            });
          }
        }}
      >
        {props.transaction_id.slice(0, 6)}...{props.transaction_id.slice(-6)}
      </Td>

      <Td>
        <Text mt={1} fontSize={"xs"} color={"gray.400"}>
          Purchase Price
        </Text>
        <Flex alignItems="center">
          <Flex cursor="default" alignItems="flex-start">
            <Text color={props.value > 0 ? "green.300" : "red.400"}>
              {parseFloat(parseFloat(props.value).toFixed(7))}
            </Text>
            <Text
              color={props.value > 0 ? "green.300" : "red.400"}
              ml="1"
              fontSize={"xs"}
            >
              {" "}
              {props.txn.cryptoType}
            </Text>
            <Text color={"gray.300"} mx={2}>
              •
            </Text>
            <Text>
              {props.fiat_at_purchase.amount === null
                ? "No Price Available"
                : `$${Math.abs(
                    props.fiat_at_purchase.amount
                  ).toLocaleString()}`}
            </Text>
          </Flex>
        </Flex>
        <Text mt={1} fontSize={"xs"} color={"gray.400"}>
          Current Value
        </Text>
        <Flex alignItems="center">
          <Text mt={1}>
            {props.marketPrice === undefined
              ? "No Price Found"
              : `${Math.abs(
                  props.marketPrice.price * props.value
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  maxFractionDigits: 2,
                })}`}
          </Text>

          {props.fiat_at_purchase.amount !== null &&
            (props.value === 0 ? (
              ""
            ) : (
              <Text color={"gray.300"} mx={2}>
                •
              </Text>
            ))}

          {props.value !== 0 &&
            (props.fiat_at_purchase.amount === null ? (
              ""
            ) : (
              <Text mt={1}>
                {props.marketPrice === undefined
                  ? "No Price Available"
                  : `${Math.abs(
                      ((props.marketPrice.price * Math.abs(props.value) -
                        props.fiat_at_purchase.amount) /
                        props.fiat_at_purchase.amount) *
                        100
                    ).toFixed(2)}%`}
              </Text>
            ))}

          {/* {props.value === 0 && (
            <Text mt={1}>
              {props.marketPrice === undefined ? "No Price Found" : `0%`}
            </Text>
          )} */}

          {props.marketPrice &&
            (props.fiat_at_purchase.amount === null ? (
              ""
            ) : props.marketPrice.price * Math.abs(props.value) -
                props.fiat_at_purchase.amount >
              0 ? (
              <TiArrowSortedUp
                style={{
                  marginLeft: "2px",
                  transform: "translateY(2px)",
                  color: "#13b93c",
                  display: props.value === 0 ? "none" : "block",
                }}
              />
            ) : (
              <TiArrowSortedDown
                style={{
                  marginLeft: "2px",
                  transform: "translateY(2px)",
                  color: "#f56565",
                  display: props.value === 0 ? "none" : "block",
                }}
              />
            ))}
        </Flex>
      </Td>
      <Td color={props.value > 0 ? "green.300" : "red.400"}>
        {props.fee ? (
          <>
            {props.fee?.amount === null ? "No Fee" : props.fee?.amount}{" "}
            {props.fee?.amount === null ? "" : props.fee?.ticker}
          </>
        ) : (
          <>No Fee</>
        )}
      </Td>
    </Tr>
  );
};
