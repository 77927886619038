import React from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "../../firebase";

export const PrivateRoute = ({ children, ...rest }) => {
  const auth = useAuth();

  return auth.user ? children : <Navigate to="/signup" />;
};
