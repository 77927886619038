import React from "react";
import { Tr, Th } from "@chakra-ui/react";

export const TableHead = () => {
  return (
    <Tr>
      {/* If we end up using more than this one table head we can remove this component and just use Chakras */}
      <Th>Date</Th>
      <Th>Category</Th>
      <Th>Payee</Th>
      <Th>Memo</Th>
      <Th>Transaction</Th>
      <Th>Amount</Th>
      <Th>Fee</Th>
    </Tr>
  );
};
