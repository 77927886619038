import React, { useState, useEffect } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  useDisclosure,
  Text,
  Button,
  Heading,
  useToast,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { BsPlusLg, BsLock } from "react-icons/bs";
import { BiLockAlt } from "react-icons/bi";
import { useAuth, db } from "../../firebase";
import firebase from "firebase";
import { useRecoilState } from "recoil";
import { userMetaData, tierData } from "../../Atoms";

export const AddAccount = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const auth = useAuth();
  const toast = useToast();
  const [step, setStep] = useState(0);
  const [hideDrawer, setHideDrawer] = useState(true);
  const [loading, setLoading] = useState(false);
  const [accountTitle, setAccountTitle] = useState("");
  const [account, setAccount] = useState({});

  const [userMetaState] = useRecoilState(userMetaData);
  const [tierState] = useRecoilState(tierData);

  const stepOne = () => {
    return (
      <>
        <Heading fontSize={"xl"}>Connect your account</Heading>
        <Text mb={2} mt={2}>
          First, we need to connect to an exchange or wallet by clicking the
          button below.
        </Text>
        <Text mb={2} mt={2}>
          Once the popup appears, you'll be able to select one from the menu.
        </Text>
        <Button
          variant={"luumen"}
          w={"100%"}
          mt={5}
          py={6}
          isLoading={loading}
          leftIcon={
            props.accounts.length <=
            tierState[userMetaState.subscription.tier].connections ? (
              <BsPlusLg />
            ) : (
              <BiLockAlt />
            )
          }
          disabled={
            props.accounts.length >=
            tierState[userMetaState.subscription.tier].connections
          }
          onClick={() => {
            setLoading(true);
            setHideDrawer(false);
            props.vUser
              .connect()
              .onConnection(async (accountId) => {
                let acc = await props.vUser.accounts.getOne(accountId);

                setAccount(acc);

                setStep(1);
                setLoading(false);
                setHideDrawer(true);
              })
              .onError((error) => {
                setHideDrawer(true);
                let msg;
                switch (error.error_type) {
                  case 400:
                    break;
                  case 401:
                    msg = error.message;
                    break;
                  case 404:
                    msg = error.message;
                    break;
                  case 500:
                    msg = error.message;
                    break;
                  case 503:
                    msg = error.message;
                    break;
                  default:
                    msg =
                      "Please reach out to support with this error code: 0x111A";
                    break;
                }
                setLoading(false);

                if (error.error_type !== 400) {
                  toast({
                    title: "We've encountered an error...",
                    description: msg,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                }
              });
          }}
        >
          {props.accounts.length >=
          tierState[userMetaState.subscription.tier].connections
            ? "Maximum Accounts Reached"
            : "Add Account"}
        </Button>
      </>
    );
  };

  const stepTwo = () => {
    return (
      <>
        <Heading fontSize={"xl"}>Save your Account</Heading>
        <Text mb={2} mt={2}>
          Great! Now we just need to give your account a nickname.
        </Text>
        <Text mb={5} mt={2} fontStyle={"italic"}>
          Once the popup appears, you'll be able to select one from the menu.
        </Text>
        <FormControl>
          <FormLabel>Account Name</FormLabel>
          <Input
            borderRadius={0}
            bg={"white"}
            border={"2px"}
            borderColor={"gray.100"}
            _hover={{ bg: "blue.100", borderColor: "blue.300" }}
            _focus={{ bg: "blue.100", borderColor: "blue.300" }}
            size="lg"
            placeholder={account.provider?.name || "Coinbase Pro"}
            value={accountTitle}
            onChange={(e) => {
              setAccountTitle(e.target.value);
            }}
          />
        </FormControl>
        <FormControl mt="5">
          <FormLabel>Save your new account</FormLabel>
          <Button
            onClick={async () => {
              db.collection("queues")
                .doc(auth.user.uid)
                .set(
                  {
                    accounts: firebase.firestore.FieldValue.arrayUnion({
                      id: account.id,
                      title: accountTitle,
                    }),
                    email: auth.user.email,
                    count: 0,
                  },
                  { merge: true }
                )
                .then(() => {
                  toast({
                    title: "Your account is being synced.",
                    status: "info",
                    duration: 3000,
                    isClosable: true,
                  });
                  setAccount({});
                  setStep(0);
                  setAccountTitle("");
                })
                .catch((err) => {
                  toast({
                    title: "There's been an error.",
                    description:
                      "Please try adding this adding this again. Sorry about that.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                });
            }}
            size="lg"
            w="100%"
            borderRadius={0}
            bg={"white"}
            border={"2px"}
            borderColor={"gray.100"}
            _hover={{ bg: "blue.100", borderColor: "blue.300" }}
            disabled={accountTitle === "" ? true : false}
          >
            Save
          </Button>
        </FormControl>
      </>
    );
  };

  return (
    <>
      <Button
        onClick={onOpen}
        variant={"luumen"}
        size={"lg"}
        leftIcon={<BsPlusLg />}
        disabled={props.accounts.length > 5}
      >
        Add Account
      </Button>
      <Drawer
        isOpen={isOpen && hideDrawer}
        placement="right"
        onClose={onClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add a New Account</DrawerHeader>

          <DrawerBody>
            {step === 0 ? stepOne() : stepTwo()}

            {/* <CreateAccount userTxns={props.userTxns} /> */}
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
