import { Box, Grid, GridItem, Heading, Center, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAuth, db } from "../../firebase";
import { useRecoilState } from "recoil";
import { marketData } from "../../Atoms";
import { Accounts } from "../Snapshot/Accounts";
import { Transactions } from "../Snapshot/Transactions";
import { Portfolio } from "../Snapshot/Portfolio";
import axios from "axios";

export const Overview = () => {
  const auth = useAuth();
  const [transactions, setTransactions] = useState([]);
  const [accounts, SetAccounts] = useState([]);
  const [balanceHistory, setBalanceHistory] = useState([]);
  const [dateHistory, setDateHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [balLoading, setBalLoading] = useState(false);

  useEffect(() => {
    setBalLoading(true);
    axios
      .post(
        "https://us-central1-luumen-finance.cloudfunctions.net/api/vezgo/balance/history", //`https://us-central1-luumen-finance.cloudfunctions.net/api/luumen/opensea`,
        {
          userId: auth.user.uid,
        }
      )
      .then(async (res) => {
        let dates = [];
        let balances = [];

        res.data.forEach((element) => {
          dates.push(new Date(element.date).toLocaleDateString());
          balances.push(Number(element.fiat_value).toFixed(2));
        });

        setBalanceHistory(balances);
        setDateHistory(dates);
        setBalLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    db.collection("users")
      .doc(auth.user.uid)
      .collection("budgets")
      .doc("budget_1")
      .onSnapshot(async (doc) => {
        if (doc.exists) {
          let allTxns = [];

          await doc.data().accounts.map((item) => {
            const updatedTxns = item.txns.map((e) => ({
              ...e,
              account: item.accountTitle,
            }));
            return (allTxns = [...allTxns, ...updatedTxns]);
          });

          setTransactions(
            allTxns.sort((a, b) => (a.date > b.date ? -1 : 1)).slice(0, 5)
          );

          SetAccounts(doc.data().accounts);
          setLoading(false);
        }
      });
  }, []);

  return (
    <Box>
      <Grid
        minH={"100vh"}
        templateRows="repeat(4, 1fr)"
        templateColumns="repeat(6, 1fr)"
        gap={3}
      >
        <GridItem
          rowSpan={2}
          colSpan={4}
          px={3}
          pt={3}
          bg="white"
          borderRadius={"3px"}
          border="2px"
          borderColor="gray.100"
        >
          <Portfolio
            loading={balLoading}
            balances={balanceHistory}
            dates={dateHistory}
          />
        </GridItem>
        <GridItem
          rowSpan={2}
          colSpan={2}
          p={3}
          bg="white"
          borderRadius={"3px"}
          border="2px"
          borderColor="gray.100"
        >
          <Accounts loading={loading} accounts={accounts} />
        </GridItem>
        <GridItem
          rowSpan={2}
          colSpan={4}
          p={3}
          bg="white"
          borderRadius={"3px"}
          border="2px"
          borderColor="gray.100"
        >
          <Center flexDir="column" opacity={0.2} h={"full"}>
            <Heading>NFT Collections Coming Soon</Heading>
            <Text mt={5} maxW={750} textAlign={"center"}>
              Tracking how your numerous NFTs are doing can be quite difficult,
              we'll show you all of your collection data here. Thank you for
              your patience!
            </Text>
          </Center>
        </GridItem>
        <GridItem
          rowSpan={2}
          colSpan={2}
          p={3}
          bg="white"
          borderRadius={"3px"}
          border="2px"
          borderColor="gray.100"
        >
          <Transactions loading={loading} transactions={transactions} />
        </GridItem>
      </Grid>
    </Box>
  );
};
